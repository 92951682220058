import React from "react";
import { FormLabelProps } from "../../../interface/FormLabel";

function FormLabel({ className, children, ...props }: FormLabelProps) {
  return (
    <label
      {...props}
      className={`sm:text-sm sm:leading-[18px] text-site-black block mb-2 font-normal ${
        className ? className : ""
      }`}
    >
      {children}
    </label>
  );
}

export default FormLabel;
