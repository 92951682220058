import React, { useState } from "react";
import Breadcrumb from "../../../components/core/Breadcrumb";
import { pathRoute } from "../../../routes/pathRoute";
import { FaCircleCheck } from "react-icons/fa6";
import OpportunityDummy from "../../../assets/images/opportunitiy_dummy.png";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Paragraph from "../../../components/core/typography/Paragraph";
import IconUsers from "../../../assets/images/icon_users.svg";
import { Link } from "react-router-dom";
import Button from "../../../components/core/form-components/Button";
import Modal from "../../../components/core/Modal";
import AppliedSuccessfull from "./AppliedSuccessfull";

function OpportunityDetail() {
  const [open, setOpen] = useState(false);
  const breadcrumbPages = [
    { name: "Opportunities", path: pathRoute.website.opportunities },
    { name: "Opportunity detail" },
  ];
  const closeModal = () => {
    setOpen(!open);
  };
  return (
    <div className="grid grid-cols-12 lg:gap-6 md:gap-4 gap-3 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="col-span-12">
        <Breadcrumb pages={breadcrumbPages} />
      </div>
      <div className="xl:col-span-5 md:col-span-6 col-span-12">
        <img
          src={OpportunityDummy}
          alt="opportunity"
          width={541}
          height={389}
          className="w-full"
        />
      </div>
      <div className="xxl:col-span-5 xl:col-span-7 md:col-span-6 col-span-12">
        <Paragraph text24 className="mb-3">
          Popstar Summer Party
        </Paragraph>
        <Paragraph
          text18
          className="!leading-4 !text-dark-grey !font-normal flex items-center gap-1 mb-6"
        >
          <img src={IconUsers} alt="icon-users" />
          Candidates: 4
        </Paragraph>
        <Paragraph text18 className="!text-[#737373] !font-normal mb-3">
          The "Popstar Summer Party" event is a lively celebration filled with
          music, dance, and entertainment featuring popular pop artists and
          performers.
        </Paragraph>
        <Paragraph text18 className="!text-dark-grey mb-3">
          Here are some possible details for such an event:
        </Paragraph>
        <ul className="list-disc pl-5">
          <li className="mb-3">
            <Paragraph text18 className="!text-dark-grey !font-normal mb-1.5">
              <span className="text-site-black">Performers:</span> The event
              could feature a lineup of popular pop stars and bands, Explore the
              below link for this event performer.
            </Paragraph>
            <Link
              to={
                "https://www.artgapi.com/en/post/museum-night-it-s-not-coachella-but-it-s-fun"
              }
              className="text-[#61AEE7] text-lg break-words"
            >
              https://www.artgapi.com/en/post/museum-night-it-s-not-coachella-but-it-s-fun
            </Link>
          </li>
          <li className="mb-3">
            <Paragraph text18 className="!text-dark-grey !font-normal mb-1.5">
              <span className="text-site-black">Performers:</span> The event
              could feature a lineup of popular pop stars and bands, Explore the
              below link for this event performer.
            </Paragraph>
            <Link
              to={
                "https://www.artgapi.com/en/post/museum-night-it-s-not-coachella-but-it-s-fun"
              }
              className="text-[#61AEE7] text-lg break-words"
            >
              https://www.artgapi.com/en/post/museum-night-it-s-not-coachella-but-it-s-fun
            </Link>
          </li>
        </ul>
        <Button primary className="w-full mt-4" onClick={() => setOpen(true)}>
          Apply Now
        </Button>
        <Button
          primary
          className="w-full !bg-site-green mt-4 !text-white"
          onClick={() => setOpen(true)}
        >
          <FaCircleCheck color="#fff" size={16} />
          Applied
        </Button>
        <Modal open={open} onClose={closeModal} header width=" max-w-[424px]">
          <AppliedSuccessfull onClose={closeModal} />
        </Modal>
      </div>
    </div>
  );
}

export default OpportunityDetail;
