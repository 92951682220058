import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/black_logo.svg";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Paragraph from "../../../components/core/typography/Paragraph";
import FormLabel from "../../../components/core/typography/FormLabel";
import InputType from "../../../components/core/form-components/InputType";
import TextLink from "../../../components/core/form-components/TextLink";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/core/form-components/Button";
import { FaApple, FaFacebook } from "react-icons/fa";
import { pathRoute } from "../../../routes/pathRoute";
import {
  loginValidationSchema,
  phoneNumberValidationSchema,
} from "../../../validations/website/loginValidationSchema";
import SelectType from "../../../components/core/form-components/SelectType";
import { getCountriesByInfo } from "../../../services/countryService";
import { LoginSocialFacebook } from "reactjs-social-login";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";

interface LoginFormValues {
  emailNumber: string;
  password?: string;
  countryCode?: string;
}

const Login = () => {
  const initialValues: LoginFormValues = {
    emailNumber: "",
    password: "",
    countryCode: "+33",
  };

  const [isNumber, setIsNumber] = useState<boolean | null>(false);
  const [showPassword, setShowPassword] = useState<boolean | null>(false);
  const [countryList, setCountryList] = useState<
    { label: string; value: string }[]
  >([]);
  const fbAppId = process.env.REACT_APP_FB_APP_ID;
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  console.log("language", language);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let queryString = `?sort_by=code&order=asc`;

        const response = await getCountriesByInfo(queryString);
        const formattedData = response?.data?.countries.map(
          (category: any) => ({
            label: category.code,
            value: category.code,
          })
        );
        setCountryList(formattedData || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleFacebookLogin = (response: any) => {
    console.log("Facebook login response:", response);
    // Send the token to your backend for verification
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    const value = e.target.value;
    setFieldValue("emailNumber", value);
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    const isNumber = /^\d+$/.test(value);

    if (isEmailValid) {
      setIsNumber(false);
      setShowPassword(true);
    } else if (isNumber) {
      setIsNumber(true);
      setShowPassword(false);
    } else {
      setIsNumber(false);
      setShowPassword(false);
    }
  };

  const onSubmit = (values: LoginFormValues) => {
    console.log("Form Data values: ", values);
  };
  const REDIRECT_URI = "http://localhost:3000/register";
  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Link
        to={pathRoute?.auth.social}
        className="absolute top-[30px] right-[30px]"
      >
        <IoCloseOutline color="#333333" size={30} />
      </Link>
      <Paragraph text24 className="mb-[10px] !font-semibold">
        {LanguageTranslation({
          labelName: "sign_in",
          languageCode: langId || "fr",
        }) || "Sign In"}
      </Paragraph>
      <Paragraph text18 className="font-normal mb-5 !text-dark-grey">
        {LanguageTranslation({
          labelName: "discover_a_wide_range",
          languageCode: langId || "fr",
        }) ||
          "Discover a wide range of Artwork created by our community of passionate artists"}
      </Paragraph>
      <Formik
        initialValues={initialValues}
        validationSchema={
          isNumber ? phoneNumberValidationSchema : loginValidationSchema
        }
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <div className="form-group">
              <FormLabel>
                {LanguageTranslation({
                  labelName: "email_contact_number",
                  languageCode: langId || "fr",
                }) || "Email / Contact number"}
                <span className="text-site-red">*</span>
              </FormLabel>
              {isNumber ? (
                <>
                  <div className="flex items-stretch border border-medium-grey rounded-lg">
                    <SelectType
                      options={countryList}
                      className=" border-0 "
                      placeholder={"Select"}
                      onChange={(option) =>
                        setFieldValue("countryCode", option?.value)
                      }
                      value={
                        countryList?.find(
                          (option) => option?.value === values?.countryCode
                        ) || ""
                      }
                    ></SelectType>
                    <InputType
                      fullWidth
                      name="emailNumber"
                      type="number"
                      value={values.emailNumber}
                      placeholder="Enter email/ contact number"
                      className="border-0"
                      onChange={(e) => {
                        handleChange(e, setFieldValue);
                      }}
                      showError={false}
                    />
                  </div>
                  {errors?.emailNumber && (
                    <div className="text-site-red text-sm font-medium mt-2">
                      {errors?.emailNumber}
                    </div>
                  )}
                </>
              ) : (
                <div className="form-group">
                  <InputType
                    name="emailNumber"
                    type="email"
                    value={values.emailNumber}
                    placeholder="Enter email/ contact number"
                    className="mb-1.5"
                    onChange={(e) => {
                      handleChange(e, setFieldValue);
                    }}
                  />
                </div>
              )}
            </div>
            {!isNumber && showPassword && (
              <>
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "password",
                    languageCode: langId || "fr",
                  }) || "Password"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Type here"
                  type="password"
                  name="password"
                  className="mb-2"
                />
              </>
            )}
            <div className="text-right mb-7">
              <TextLink
                className="!font-normal"
                to={pathRoute.auth.forgotPassword}
              >
                {LanguageTranslation({
                  labelName: "forgot_password",
                  languageCode: langId || "fr",
                }) || "Forgot Password?"}
              </TextLink>
            </div>
            <Button
              primary
              type="submit"
              className="w-full"
              disabled={isSubmitting}
            >
              {LanguageTranslation({
                labelName: "sign_in",
                languageCode: langId || "fr",
              }) || "Sign In?"}
            </Button>
            <Paragraph text14 className="text-center !font-normal mt-4 mb-5">
              {LanguageTranslation({
                labelName: "dont_have_account",
                languageCode: langId || "fr",
              }) || "Don't have an account?"}{" "}
              <TextLink to={pathRoute.auth.register}>
                {LanguageTranslation({
                  labelName: "sign_up",
                  languageCode: langId || "fr",
                }) || "Sign Up"}
              </TextLink>
            </Paragraph>
            <Paragraph
              text18
              className="mb-6 flex justify-center items-center gap-3 font-normal before:w-[100px] before:h-[1px] before:bg-medium-grey before:block after:w-[100px] after:h-[1px] after:bg-medium-grey after:block !text-medium-grey"
            >
              OR
            </Paragraph>
            <div className="flex items-center justify-center gap-4 mb-6">
              <button className="flex items-center gap-2 border border-medium-grey hover:border-site-black ease-in-out transition-all duration-300 rounded-3xl px-4 py-2">
                <FaApple size={28} />
                <span className="block">
                  <span className="block text-xs leading-3 text-[#737373] mb-[2px] text-left">
                    Sign in with
                  </span>
                  <span className="block text-[18px] leading-[16px] font-semibold text-left">
                    Apple
                  </span>
                </span>
              </button>
              <LoginSocialFacebook
                appId={fbAppId || ""}
                // fieldsProfile={
                //   "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                // }
                onResolve={handleFacebookLogin}
                onReject={(error) => {
                  console.error("Facebook login error:", error);
                }}
                // redirect_uri={REDIRECT_URI}
              >
                <button className="flex items-center gap-2 border border-medium-grey hover:border-[#4487EC] ease-in-out transition-all duration-300 rounded-3xl px-4 py-2">
                  <FaFacebook color="#4487EC" size={26} />
                  <span className="block">
                    <span className="block text-xs leading-3 text-[#737373] mb-[2px] text-left">
                      Sign in with
                    </span>
                    <span className="block text-[18px] leading-[16px] font-semibold text-left text-[#4487EC]">
                      Facebook
                    </span>
                  </span>
                </button>
              </LoginSocialFacebook>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
