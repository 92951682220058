import React, { useRef, useState } from "react";
import { Form, Formik, FormikErrors } from "formik";
import Logo from "../../../assets/images/black_logo.svg";
import { Link } from "react-router-dom";
import Paragraph from "../../../components/core/typography/Paragraph";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Button from "../../../components/core/form-components/Button";

interface FormValues {
  otp: string;
}

function OTP(): JSX.Element {
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    } else {
      e.target.value = "";
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  // const handlePaste = (e) => {
  //   e.preventDefault();
  //   const pasteData = e.clipboardData.getData("text").slice(0, 6).split("");
  //   const newOtp = [...otp];
  //   pasteData.forEach((char: any, index: number) => {
  //     if (/^[0-9]$/.test(char) && index < inputRefs.current.length) {
  //       newOtp[index] = char;
  //     }
  //   });
  //   setOtp(newOtp);
  //   if (pasteData.length > 0) {
  //     inputRefs.current[
  //       Math.min(pasteData.length, inputRefs.current.length) - 1
  //     ].focus();
  //   }
  // };

  const onSubmit = (values: FormValues) => {
    console.log("Form Data: ", values);
  };

  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Paragraph text24 className="mb-[10px] !font-semibold">
        OTP Verification
      </Paragraph>
      <Paragraph text18 className="font-normal mb-6 !text-dark-grey">
        Enter verification code we just sent on your mobile number.
      </Paragraph>
      <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-12">
              <div className="col-span-12 mb-3">
                <div className="flex sm:gap-6 gap-4 ">
                  {Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength={1}
                        className="text-site-black text-2xl leading-6 font-normal w-[48px] h-[48px] text-center px-3 py-4 rounded-lg border border-site-grey focus:outline-0 focus:border-primary-blue"
                        value={otp[index]}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        // onPaste={handlePaste}
                      />
                    ))}
                </div>
                {errorMessage && (
                  <div className="text-site-red text-sm font-medium">
                    {errorMessage}
                  </div>
                )}
              </div>
              <div className="col-span-12 mb-6">
                <Paragraph text14 className="!font-normal">
                  Expiring in <span className="text-site-red">01:24</span>
                </Paragraph>
              </div>
              <div className="col-span-12 mb-3">
                <Button
                  // primary
                  className={"w-full"}
                  type="submit"
                  // disabled={isSubmitting}
                  disabled
                >
                  Verify
                </Button>
              </div>
              <div className="col-span-12 text-center">
                <Paragraph text14 className={"!text-dark-grey !font-normal"}>
                  Didn't receive OTP?{" "}
                  <button
                    type="button"
                    className="text-site-black font-semibold hover:text-site-yellow transition-all duration-300"
                  >
                    Resend
                  </button>
                </Paragraph>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OTP;
