import React from "react";

interface languageInterface {
  labelName: string;
  languageCode: string;
}
export function LanguageTranslation({
  labelName,
  languageCode,
}: languageInterface) {
  const data = [
    {
      id: 1,
      labelName: "sign_up",
      labels: [
        {
          languageName: "french",
          languageCode: "fr",
          value: "s'inscrire",
        },
        {
          languageName: "english",
          languageCode: "en",
          value: "sign up",
        },
      ],
    },
    {
      id: 2,
      labelName: "sign_in",
      labels: [
        {
          languageName: "french",
          languageCode: "fr",
          value: "Se connecter",
        },
        {
          languageName: "english",
          languageCode: "en",
          value: "sign in",
        },
      ],
    },
  ];

  const labelObject = data?.find((item) => item.labelName === labelName);

  //   if (!labelObject) {
  //     return "Label not found"; // Handle case where labelName doesn't exist
  //   }

  // Find the specific label value for the given languageCode
  const label = labelObject?.labels?.find(
    (label) => label.languageCode === languageCode
  );

  return label
    ? label.value
    : labelObject?.labels.find((label) => label.languageCode === "fr")?.value ||
        "";
}
