import React from "react";
import DummyProfile from "../../../assets/images/dummy_logo.png";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileSelector } from "../../../redux/slices/profileSlice";
import Paragraph from "../../../components/core/typography/Paragraph";

function ProfileHeader() {
  const { t } = useTranslation();
  const profileData = useSelector(profileSelector);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };
  console.log("profileData", profileData);
  return (
    <>
      <section
        className={`md:pt-[50px] pt-[30px] ${
          profileData?.coverImage
            ? "z-0 relative before:absolute before:w-full before:h-full before:bg-white/20 before:top-0 before:left-0 before:-z-[1]"
            : `bg-gradient-to-t from-site-black to-[${profileData?.coverColor?.hexcode}]-500`
        }`}
        style={
          profileData?.coverImage
            ? {
                backgroundImage: `url(${profileData.coverImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }
            : undefined
        }
      >
        <div
          className={`container md:pb-10 pb-[30px]  ${
            profileData?.coverImage || profileData?.coverColor?.hexcode
              ? ""
              : "border-b border-medium-grey"
          }`}
        >
          <div className="grid grid-cols-12 lg:gap-6 sm:gap-4 gap-3 items-center">
            <div className="lg:col-span-3 sm:col-span-4 col-span-12">
              <div className="img-block xl:w-[242px] xl:h-[242px] lg:w-[200px] lg:h-[200px] sm:w-[160px] sm:h-[160px] w-[100px] h-[100px] rounded-full flex-shrink-0 overflow-hidden">
                <img
                  src={getProfileImage(profileData?.avatar ?? "")}
                  alt="profile-photo"
                  className="w-full h-full object-cover"
                  width={"242px"}
                  height={"242px"}
                />
              </div>
            </div>
            <div className="lg:col-span-9 sm:col-span-8 col-span-12">
              <Paragraph
                text32
                className={`mb-[6px] `}
                style={{
                  color: profileData?.textColor,
                }}
              >
                @{profileData?.nickname}
              </Paragraph>
              <Paragraph
                text18
                className={`flex items-center leading-[17px] gap-1 mb-6 `}
                style={{
                  color: profileData?.textColor,
                }}
              >
                <span>
                  <FaMapMarkerAlt size={18} />
                </span>
                {profileData?.country?.name}
              </Paragraph>
              <div className="flex items-center gap-[30px] mb-[18px]">
                <Paragraph
                  text22
                  className={`flex items-center gap-2 `}
                  style={{
                    color: profileData?.textColor,
                  }}
                >
                  {profileData?.followers ? profileData?.followers : 0}
                  <span
                    className={`text-[20px]  leading-[18px] ${
                      profileData?.textColor ? `` : "text-dark-grey"
                    }  font-normal`}
                    style={{
                      color: profileData?.textColor,
                    }}
                  >
                    {t("profile.followers")}
                  </span>
                </Paragraph>
                <Paragraph
                  text22
                  className={`flex items-center gap-2 `}
                  style={{
                    color: profileData?.textColor,
                  }}
                >
                  {profileData?.post ? profileData?.post : 0}
                  <span
                    className={`text-[20px]  leading-[18px] ${
                      profileData?.textColor ? `` : "text-dark-grey"
                    }  font-normal`}
                    style={{
                      color: profileData?.textColor,
                    }}
                  >
                    {t("profile.post")}
                  </span>
                </Paragraph>
              </div>
              {profileData?.interest && profileData.interest.length > 0 && (
                <>
                  <Paragraph
                    text18
                    className={`mb-2 leading-[17px] `}
                    style={{
                      color: profileData?.textColor,
                    }}
                  >
                    {t("profile.interest")}
                  </Paragraph>
                  <ul className="flex flex-wrap items-center gap-[10px]">
                    {profileData?.interest?.map((item) => (
                      <li
                        key={item.id}
                        className={`border  rounded-[20px]   ${
                          profileData?.textColor ? `` : "border-medium-grey"
                        } py-[6px] px-4 text-base leading-[15px]`}
                        style={{
                          color: profileData?.textColor,
                          borderColor: profileData?.textColor,
                          fontWeight: 500,
                        }}
                      >
                        {item.interest.title}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ProfileHeader;
