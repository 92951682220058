import React from "react";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import { FaEnvelope } from "react-icons/fa";
import { Form, Formik } from "formik";
import FormLabel from "../../../../../components/core/typography/FormLabel";
import InputType from "../../../../../components/core/form-components/InputType";
import TextArea from "../../../../../components/core/form-components/TextArea";
import { FiPlus } from "react-icons/fi";
import DummyImg from "../../../../../assets/images/art2.png";
import { IoClose } from "react-icons/io5";
import Button from "../../../../../components/core/form-components/Button";
interface FormValues {
  otp: string;
}
function ContactUs() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const onSubmit = (values: FormValues) => {
    console.log("Form Data: ", values);
  };
  return (
    <div className="lg:p-30 md:p-4 p-3 !pt-0 grid grid-cols-12">
      <div className="xl:col-span-8 col-span-12">
        <Paragraph text20 className="!font-medium mb-4">
          {LanguageTranslation({
            labelName: "contact_us",
            languageCode: langId || "fr",
          }) || "Contact Us"}
        </Paragraph>
        <a
          href="mailto:"
          className="bg-[#F3F3F3] px-3 py-1.5 rounded-[50px] inline-flex items-center gap-1.5 text-sm leading-[16px] font-medium mb-3"
        >
          <FaEnvelope />
          {LanguageTranslation({
            labelName: "email",
            languageCode: langId || "fr",
          }) || "Email"}
        </a>
        <Paragraph text16 className="!text-dark-grey mb-6">
          {LanguageTranslation({
            labelName: "contact_us_via_email",
            languageCode: langId || "fr",
          }) || "You can contact us via email"}
        </Paragraph>
        <Paragraph text20 className="!font-medium mb-4">
          {LanguageTranslation({
            labelName: "report_bug",
            languageCode: langId || "fr",
          }) || "Report a bug"}
        </Paragraph>
        <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "title",
                    languageCode: langId || "fr",
                  }) || "Title"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  required
                  name="name"
                  type="text"
                  placeholder="Enter name"
                />
              </div>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "description",
                    languageCode: langId || "fr",
                  }) || "Description"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <TextArea
                  name="description"
                  rows={3}
                  placeholder="What do you want to share"
                />
              </div>
              <div className="form-group lg:mb-6 mb-30">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "upload_file",
                    languageCode: langId || "fr",
                  }) || "Upload File"}
                </FormLabel>
                <div className="flex gap-4 mb-2">
                  <label
                    htmlFor="file1"
                    className="relative cursor-pointer border border-dashed border-medium-grey rounded-[4px] lg:w-[100px] lg:h-[100px] w-[60px] h-[60px] flex items-center justify-center"
                  >
                    <img
                      src={DummyImg}
                      alt=""
                      className="w-full h-full object-cover rounded-[4px]"
                    />
                    <span className="absolute -top-1.5 -right-1.5 w-4 h-4 rounded-full bg-site-red text-white cursor-pointer">
                      <IoClose />
                    </span>
                  </label>
                  <label
                    htmlFor="file2"
                    className="relative cursor-pointer border border-dashed border-medium-grey rounded-[4px] lg:w-[100px] lg:h-[100px] w-[60px] h-[60px] flex items-center justify-center"
                  >
                    <input type="file" name="" id="file2" className="sr-only" />
                    <FiPlus color="#4D4B4B" size={24} />
                  </label>
                  <label
                    htmlFor="file3"
                    className="relative cursor-pointer border border-dashed border-medium-grey rounded-[4px] lg:w-[100px] lg:h-[100px] w-[60px] h-[60px] flex items-center justify-center"
                  >
                    <input type="file" name="" id="file3" className="sr-only" />
                    <FiPlus color="#4D4B4B" size={24} />
                  </label>
                </div>
                <Paragraph text16 className="!text-dark-grey">
                  {LanguageTranslation({
                    labelName: "upload_upto_3",
                    languageCode: langId || "fr",
                  }) || "You can upload up to 3 files only"}
                </Paragraph>
              </div>
              <Button disabled>Send</Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ContactUs;
