import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Progress } from "reactstrap";
import BasicInfo from "./steps/BasicInfo";
import PaymentInfo from "./steps/PaymentInfo";
import ShippingAddress from "./steps/ShippingAddress";
import OtherInfo from "./steps/OtherInfo";
import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ArtDetails } from "../../../interface/ArtDetails";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { getArtRoomDetailsById } from "../../../services/profileService";
import { useNavigate, useParams } from "react-router-dom";
import { tokenSelector } from "../../../redux/slices/userSlice";
import Breadcrumb from "../../../components/core/Breadcrumb";
import PaymentDetailsCheckout from "./PaymentDetailsCheckout";
import Stepper from "../../../components/core/Stepper";
import { useTranslation } from "react-i18next";
import {
  servicesSelector,
  setServices,
} from "../../../redux/slices/otherServices";
import {
  CheckOtherServices,
  CheckShippingAddress,
} from "../../../services/checkoutService";
import { setPaymentInfo } from "../../../redux/slices/paymentInfo";
import {
  isConfirmService,
  isConfirmServiceSelector,
  notConfirmService,
} from "../../../redux/slices/isConfirmServicesSlice";
import stepperValidationSchema from "../../../validations/stepperValidationSchema";
import { pathRoute } from "../../../routes/pathRoute";

const stepsContent = ["Step 1", "Step 2", "Step 3", "Step 4"];

const MultiStepDemo = () => {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = stepperValidationSchema[activeStep];

  const [artDetails, setArtDetails] = useState<ArtDetails | undefined>(
    undefined
  );
  const [showButton, setShowButton] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const { id } = useParams();
  const profileUrlToken = `${pathRoute.web.profile}?token=${token}`;
  const artUrlToken = `${pathRoute.web.artDetail}/${id}?token=${token}`;
  const checkoutUrlToken = `${pathRoute.web.checkout}/${id}?token=${token}`;
  const services = useSelector(servicesSelector);
  const isConfirm = useSelector(isConfirmServiceSelector);

  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    phone_country_code: "+33",
    firstName: "",
    lastName: "",
    cardHolderName: "",
    cardExpiry: "",
    cardNumber: "",
    cardCvc: "",
    shipping_address: "",
    shipping_city: "",
    shipping_zip: "",
    shiiping_country: "",
    billing_address: "",
    billing_city: "",
    billing_zip: "",
    blilling_country: "",
    isBillingSame: true,
    id: id ? parseInt(id) : 0,
  };

  const fetchArtRoomDetails = async (id: string) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getArtRoomDetailsById(parseInt(id));
        if (response?.data?.code === 200) {
          setArtDetails(response?.data?.art);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchArtRoomDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (artDetails?.quantity === 0 && token) {
      const urlWithToken = `${pathRoute.web.profile}?token=${token}`;
      navigate(urlWithToken);
    }
  }, [artDetails]);

  const breadcrumbPages = [
    { name: "Profile", path: profileUrlToken },
    { name: artDetails?.title || "", path: artUrlToken },
    { name: "Checkout", path: checkoutUrlToken },
  ];

  const steps = [
    {
      name: t("checkout.basic_info"),
      content: <BasicInfo />,
    },
    {
      name: t("checkout.paymnet"),
      content: <PaymentInfo />,
    },
    { name: t("checkout.shipping_address"), content: <ShippingAddress /> },
    { name: t("checkout.other_info"), content: <OtherInfo /> },
  ];
  const stripe = useStripe();
  const elements = useElements();

  const _handleSubmit = async (values: any, actions: any) => {
    // setActiveStep(activeStep + 1);
    // actions.setTouched({});
    // actions.setSubmitting(false);
    const errors = await actions.validateForm();
    if (Object.keys(errors).length === 0) {
      dispatch(showLoader());
      try {
        if (activeStep === 0) {
          setActiveStep((prev) => Math.min(prev + 1, stepsContent.length - 1));
          actions.setTouched({});
          actions.setSubmitting(false);
        } else if (activeStep === 1) {
          if (!stripe || !elements) {
            return;
          }
          const cardElement = elements.getElement(CardNumberElement);
          if (cardElement) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
              type: "card",
              card: cardElement,
              billing_details: {
                name: values.cardHolderName,
              },
            });
            if (error) {
              console.error("Error creating payment method: ", error);
              return;
            } else {
              actions.setFieldValue("paymentMethodId", paymentMethod?.id);
              setActiveStep((prev) =>
                Math.min(prev + 1, stepsContent.length - 1)
              );
              actions.setTouched({});
              actions.setSubmitting(false);
              // Handle other steps if needed
            }
          }
        } else if (activeStep === 2) {
          let data = {
            address: values?.shipping_address,
            city: values?.shipping_city,
            zip: values?.shipping_zip,
            country: values?.shiiping_country,
            billingAddress: values?.billing_address,
            billingCity: values?.billing_city,
            billingZip: values?.billing_zip,
            billingCountry: values?.blilling_country,
          };
          const response = await CheckShippingAddress(
            data,
            id ? parseInt(id) : 0
          );
          if (response?.data?.code === 200) {
            dispatch(setServices(response?.data));
            setActiveStep((prev) =>
              Math.min(prev + 1, stepsContent.length - 1)
            );
            actions.setTouched({});
            actions.setSubmitting(false);
          }
        } else if (activeStep === steps.length - 1) {
          const otherServiceData = {
            buyerCityId: services?.buyerCityId,
            sellerCityId: services?.sellerCityId,
            serviceCode:
              services?.transportServices?.computedServicesPrices[0]
                ?.serviceCode,
            artId: values?.id,
            insurance: values?.insurance,
          };
          const response = await CheckOtherServices(otherServiceData);
          if (response?.data?.code === 200) {
            dispatch(setPaymentInfo(response?.data));
            setActiveStep((prev) =>
              Math.min(prev + 1, stepsContent.length - 1)
            );
            actions.setTouched({});
            actions.setSubmitting(false);
            dispatch(isConfirmService()); // Hide confirm
          }
        }
      } catch (error) {
        console.error("Error during step processing:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  function _handleBack() {
    setActiveStep((prev) => Math.max(prev - 1, 0));
    dispatch(notConfirmService());
  }
  const isHorizontal = true;

  return (
    <section>
      <div className="container">
        <div className="pt-4">
          <Breadcrumb pages={breadcrumbPages} />
        </div>
        <div className="md:py-[50px] py-[30px]">
          <h1 className="md:text-[32px] sm:leading-[31px] text-[28px] leading-[27px] font-semibold text-site-black md:mb-[30px] mb-5">
            Checkout
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className="grid grid-cols-12 gap-6">
                  <div className="lg:col-span-8 col-span-12">
                    <div
                      className={`flex ${
                        isHorizontal ? "flex-col" : "flex-row"
                      } items-start`}
                    >
                      <Stepper
                        steps={steps}
                        showButton={showButton}
                        setShowButton={setShowButton}
                        orientation="horizontal"
                        horizontalPosition={"self-center"}
                        activeStep={activeStep} // Track current step
                        setCurrentStep={setActiveStep} // Update stepper's current step
                        isSubmitting={isSubmitting}
                        handleBack={_handleBack}
                      />
                      <div className="mt-30 flex justify-between w-full">
                        {activeStep !== 0 && (
                          <div className="flex items-center gap-3">
                            <Button
                              onClick={_handleBack}
                              className="font-semibold !p-0 capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px]"
                            >
                              {t("checkout.previous")}
                            </Button>
                          </div>
                        )}
                        {activeStep === steps.length - 1 ? (
                          <Button
                            disabled={isConfirm}
                            type="submit"
                            primary
                            className={`justify-self-end ms-auto capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px] font-semibold bg-site-yellow text-site-black hover:text-white hover:bg-site-black hover:border-medium-grey ${
                              isConfirm ? "opacity-40" : ""
                            } `}
                          >
                            {t("checkout.confirm")}
                          </Button>
                        ) : (
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            className={
                              "justify-self-end ms-auto capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px] font-semibold bg-site-yellow text-site-black hover:text-white hover:bg-site-black hover:border-medium-grey"
                            }
                            primary
                          >
                            {t("checkout.next")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {artDetails && (
                    <PaymentDetailsCheckout
                      artDetails={artDetails}
                      currentStep={activeStep}
                      values={values}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default MultiStepDemo;
