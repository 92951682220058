import React from "react";
import MasterClassDummy1 from "../../../assets/images/master_class_dummy_1.png";
import MasterClassDummy2 from "../../../assets/images/master_class_dummy_2.png";
import { IoMdPlay } from "react-icons/io";
import { Link } from "react-router-dom";
import Paragraph from "../../../components/core/typography/Paragraph";
import { GoClockFill } from "react-icons/go";
import { pathRoute } from "../../../routes/pathRoute";

function MasterClass() {
  return (
    <div className="grid xxl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 md:gap-[18px] gap-4 xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy1}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy2}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy2}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy2}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy2}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy2}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.masterClassDetail}>
          <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
            <img
              src={MasterClassDummy2}
              alt="video-thumb"
              width={263}
              height={394}
              className="w-full"
            />

            <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
              <IoMdPlay color="#fff" size={22} />
            </span>
          </div>
          <Paragraph text20 className="!font-medium line-clamp-1 mb-3">
            Comprendre ses besoins Comprendre ses besoins
          </Paragraph>
          <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
            <GoClockFill color="#4D4B4B" size={18} />
            Class: 35 min
          </span>
        </Link>
      </div>
    </div>
  );
}

export default MasterClass;
