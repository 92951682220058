import React, { useState } from "react";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import Accordion, {
  AccordionItem,
} from "../../../../../components/core/Accordion";

function WhatsInterest() {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const handleAccordionChange = (value: string | null) => {
    console.log("Selected Accordion Item:", value);
    setSelectedValue(value);
  };
  return (
    <div>
      <Paragraph text20 className="mb-4 font-medium">
        What’s Your Interest?
      </Paragraph>

      <Accordion value={selectedValue} onChange={handleAccordionChange}>
        <AccordionItem value="item1" trigger="Visual Arts">
          <div className="flex items-center gap-3">
            <label htmlFor="painting" className="relative inline-block">
              <input
                type="checkbox"
                id="painting"
                name="visual-arts"
                className="absolute opacity-0 peer"
              />
              <span className="text-sm text-site-black font-medium border border-site-black rounded-3xl px-4 py-2 cursor-pointer peer-checked:bg-site-black peer-checked:text-white peer-checked:border-site-black transition-colors inline-block">
                Painting
              </span>
            </label>
            <label htmlFor="drawing" className="relative inline-block">
              <input
                type="checkbox"
                id="drawing"
                name="visual-arts"
                className="absolute opacity-0 peer"
                checked
              />
              <span className="text-sm text-site-black font-medium border border-site-black rounded-3xl px-4 py-2 cursor-pointer peer-checked:bg-site-black peer-checked:text-white peer-checked:border-site-black transition-colors inline-block">
                Drawing
              </span>
            </label>
          </div>
        </AccordionItem>
        <AccordionItem value="item2" trigger="Applied Arts and Design">
          <div className="flex items-center gap-3">
            <label htmlFor="performance-art" className="relative inline-block">
              <input
                type="checkbox"
                id="performance-art"
                name="applied-arts"
                className="absolute opacity-0 peer"
              />
              <span className="text-sm text-site-black font-medium border border-site-black rounded-3xl px-4 py-2 cursor-pointer peer-checked:bg-site-black peer-checked:text-white peer-checked:border-site-black transition-colors inline-block">
                Performance Art
              </span>
            </label>
            <label htmlFor="glass-art" className="relative inline-block">
              <input
                type="checkbox"
                id="drawing"
                name="glass-arts"
                className="absolute opacity-0 peer"
                checked
              />
              <span className="text-sm text-site-black font-medium border border-site-black rounded-3xl px-4 py-2 cursor-pointer peer-checked:bg-site-black peer-checked:text-white peer-checked:border-site-black transition-colors inline-block">
                Glass Art
              </span>
            </label>
          </div>
        </AccordionItem>
        <AccordionItem value="item3" trigger="Traditional and Folk Arts">
          <p>This is the content for the third item.</p>
        </AccordionItem>
        <AccordionItem value="item4" trigger="Other">
          <p>This is the content for the third item.</p>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default WhatsInterest;
