import React, { useState } from "react";
import Logo from "../../../../assets/images/black_logo.svg";
import BasicInfo from "./steps/BasicInfo";
import StepperWithProgressbar from "../../../../components/core/StepperWithProgressbar";
import HeadingOne from "../../../../components/core/typography/HeadingOne";
import Paragraph from "../../../../components/core/typography/Paragraph";
import WhatsInterest from "./steps/WhatsInterest";
import WhatsAddress from "./steps/WhatsAddress";
import { Form, Formik } from "formik";
import { Button } from "reactstrap";
import stepperValidationSchema from "../../../../validations/website/stepperValidationSchema";

const stepsContent = ["Step 1", "Step 2", "Step 3"];

function ProfileSetup() {
  const [activeStep, setActiveStep] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const currentValidationSchema = stepperValidationSchema[activeStep];

  const steps = [
    {
      name: "Basic Info",
      content: <BasicInfo />,
    },
    {
      name: "Intrest",
      content: <WhatsInterest />,
    },
    { name: "Address", content: <WhatsAddress /> },
  ];

  const initialValues = {
    name: "",
    nickName: "",
    email: "",
    birthdate: "",
    interests: [],
    address1: "",
    address2: "",
    city: "",
    zipcode: "",
    country: "",
  };

  const _handleSubmit = async (values: any, actions: any) => {
    const errors = await actions.validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        setActiveStep((prev) => Math.min(prev + 1, stepsContent.length - 1));
        actions.setTouched({});
        actions.setSubmitting(false);
      } catch (error) {
        console.error("Error during step processing:", error);
      } finally {
      }
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const _handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Paragraph text24 className="!font-semibold mb-2.5">
        Complete Your Profile
      </Paragraph>
      <Paragraph text18 className="font-normal mb-5 !text-dark-grey">
        Complete your registration and start exploring or showcasing artwork,
        please fill out the following details
      </Paragraph>

      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={_handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <StepperWithProgressbar
              steps={steps}
              showButton={showButton}
              setShowButton={setShowButton}
              orientation="horizontal"
              horizontalPosition={"self-center"}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
            <div className="mt-30 flex justify-between w-full">
              {activeStep !== 0 && (
                <div className="flex items-center gap-3">
                  <Button
                    onClick={_handleBack}
                    className="font-semibold !p-0 capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px]"
                  >
                    {"Previous"}
                  </Button>
                </div>
              )}
              {activeStep === steps.length - 1 ? (
                <Button
                  className={`justify-self-end ms-auto capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px] font-semibold bg-site-yellow text-site-black hover:text-white hover:bg-site-black hover:border-medium-grey 
                    
                   `}
                  primary
                  type="submit"
                >
                  Sign Up
                </Button>
              ) : activeStep === 0 ? (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className={
                    "w-full justify-self-end ms-auto capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px] font-semibold bg-site-yellow text-site-black hover:text-white hover:bg-site-black hover:border-medium-grey"
                  }
                  primary
                >
                  {"Continue"}
                </Button>
              ) : (
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className={
                    "justify-self-end ms-auto capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px] font-semibold bg-site-yellow text-site-black hover:text-white hover:bg-site-black hover:border-medium-grey"
                  }
                  primary
                >
                  {"Next"}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProfileSetup;
