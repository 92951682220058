import React from "react";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import Paragraph from "../../../../components/core/typography/Paragraph";
import AmazonPay from "../../../../assets/images/icon_amazon_pay.svg";
import VisaPay from "../../../../assets/images/icon_visa.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import { Form, Formik } from "formik";
import FormLabel from "../../../../components/core/typography/FormLabel";
import InputType from "../../../../components/core/form-components/InputType";
import SelectType from "../../../../components/core/form-components/SelectType";
import Button from "../../../../components/core/form-components/Button";
interface FormValues {
  otp: string;
}
function PaymentMethods() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;

  const onSubmit = (values: FormValues) => {
    console.log("Form Data: ", values);
  };
  const handleSelect = () => console.log("selected");
  const countryOptions = [
    {
      label: "+91",
      value: "+91",
    },
    {
      label: "+244",
      value: "+244",
    },
    {
      label: "+67",
      value: "+67",
    },
  ];

  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "payment_methods",
          languageCode: langId || "fr",
        }) || "Payment Methods"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        <div className="grid grid-cols-12 gap-3">
          <div className="lg:col-span-8 col-span-12">
            {/* <ul>
              <li className="mb-3">
                <label
                  htmlFor="master-card"
                  className="border border-medium-grey rounded-2xl md:p-4 p-3 flex items-center justify-between cursor-pointer"
                >
                  <input
                    type="radio"
                    name="payment-methods"
                    id="master-card"
                    className="peer sr-only"
                  />
                  <div className="inline-flex items-center md:gap-4 gap-3">
                    <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                      <img src={AmazonPay} alt="amazon-pay" />
                    </div>
                    <div>
                      <Paragraph
                        text18
                        className="!font-medium inline-flex items-center gap-3 !leading-4"
                      >
                        Mastercard
                        <span className="text-dark-grey">|</span>
                        <span className="">...1390</span>
                      </Paragraph>
                      <Paragraph
                        text14
                        className="!font-normal !text-dark-grey leading-[12px]"
                      >
                        Pay & secure with mastercard
                      </Paragraph>
                    </div>
                  </div>
                  <span className="w-6 h-6 flex-shrink-0 rounded-full border border-medium-grey inline-block peer-checked:hidden"></span>
                  <IoIosCheckmarkCircle
                    color="#1AB53C"
                    size={28}
                    className="hidden peer-checked:block flex-shrink-0"
                  />
                </label>
              </li>
              <li className="mb-3">
                <label
                  htmlFor="visa-card"
                  className="border border-medium-grey rounded-2xl md:p-4 p-3 flex items-center justify-between cursor-pointer"
                >
                  <input
                    type="radio"
                    name="payment-methods"
                    id="visa-card"
                    className="peer sr-only"
                  />
                  <div className="inline-flex items-center md:gap-4 gap-3">
                    <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                      <img src={VisaPay} alt="visa-pay" />
                    </div>
                    <div>
                      <Paragraph
                        text18
                        className="!font-medium inline-flex items-center gap-3 !leading-4"
                      >
                        Mastercard
                        <span className="text-dark-grey">|</span>
                        <span className="">...1390</span>
                      </Paragraph>
                      <Paragraph
                        text14
                        className="!font-normal !text-dark-grey leading-[12px]"
                      >
                        Pay & secure with mastercard
                      </Paragraph>
                    </div>
                  </div>
                  <span className="w-6 h-6 rounded-full border border-medium-grey inline-block peer-checked:hidden flex-shrink-0"></span>
                  <IoIosCheckmarkCircle
                    color="#1AB53C"
                    size={28}
                    className="hidden peer-checked:block flex-shrink-0"
                  />
                </label>
              </li>
              <li>
                <label className="border border-medium-grey rounded-2xl md:p-4 p-3 flex items-center justify-between cursor-pointer">
                  <div className="inline-flex items-center md:gap-4 gap-3">
                    <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                      <FiPlus color="#1AB53C" size={24} />
                    </div>
                    <div>
                      <Paragraph
                        text18
                        className="!font-medium inline-flex items-center gap-3 !leading-4"
                      >
                        Add new card
                      </Paragraph>
                      <Paragraph
                        text14
                        className="!font-normal !text-dark-grey leading-[12px]"
                      >
                        Save & Pay via Cards
                      </Paragraph>
                    </div>
                  </div>
                </label>
              </li>
            </ul> */}
            <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="md:col-span-6 col-span-12 form-group">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "card_number",
                          languageCode: langId || "fr",
                        }) || "Card number"}
                        <span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        required
                        name="card-number"
                        type="number"
                        placeholder="Enter card number"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12 form-group">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "card_holder",
                          languageCode: langId || "fr",
                        }) || "Card holder"}
                        <span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        name="card-holder"
                        placeholder="Enter card holder"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12 form-group">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "expiration_date",
                          languageCode: langId || "fr",
                        }) || "Expiration date"}
                        <span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        name="expiration-date"
                        type="date"
                        placeholder="mm/yy"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12 form-group">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "cvv",
                          languageCode: langId || "fr",
                        }) || "CVV"}
                        <span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType name="cvv" type="number" placeholder="CVV" />
                    </div>
                    <div className="md:col-span-6 col-span-12 form-group">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "country",
                          languageCode: langId || "fr",
                        }) || "Country"}
                        <span className="text-site-red">*</span>
                      </FormLabel>
                      <SelectType
                        options={countryOptions}
                        onChange={handleSelect}
                        fullWidth
                        placeholder="Select Country"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12 form-group">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "postal_code",
                          languageCode: langId || "fr",
                        }) || "Postal code"}
                        <span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        name="postal-code"
                        type="number"
                        placeholder="Enter postal code"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-4 md:mt-30 mt-5">
                    <Button borderButton>Cancel</Button>
                    <Button disabled>Save</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethods;
