import React from "react";
import WhiteLogo from "../../../assets/images/white-logo.svg";
import { IoCall } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokenSelector } from "../../../redux/slices/userSlice";
import { profileSelector } from "../../../redux/slices/profileSlice";
import { pathRoute } from "../../../routes/pathRoute";

function Header() {
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const profileData = useSelector(profileSelector);

  const handleClickLogo = () => {
    const urlWithToken = `${pathRoute.web.profile}?token=${token}`;
    navigate(urlWithToken);
  };
  console.log("profileData", profileData);
  return (
    <header
      className={`bg-primary-blue sm:py-4 py-3 w-full z-50 transition-transform duration-300 fixed top-0`}
    >
      <div className="container">
        <div className="grid grid-cols-12 lg:gap-6 sm:gap-4 gap-3 items-center">
          <div className="md:col-span-3 col-span-6">
            <a href="">
              <img src={WhiteLogo} alt="logo" onClick={handleClickLogo} />
            </a>
          </div>
          <div className="md:col-span-9 col-span-6 text-end">
            <a
              href="tel:+33 700-555-817"
              className="inline-flex items-center justify-end gap-[10px] sm:text-white text-site-black text-[18px] leading-[17px] sm:hover:text-site-yellow transition-all duration-700 sm:bg-transparent bg-white sm:p-0 p-1 sm:rounded-0 rounded-md"
            >
              <IoCall size={24} />{" "}
              <span className="sm:block hidden">
                {profileData?.country?.code} {profileData?.phoneNb}
              </span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
