import React, { useEffect, useState } from "react";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import FormLabel from "../../../../../components/core/typography/FormLabel";
import InputType from "../../../../../components/core/form-components/InputType";
import SelectType from "../../../../../components/core/form-components/SelectType";
import { useFormikContext } from "formik";
import { getCountriesByInfo } from "../../../../../services/countryService";

function WhatsAddress() {
  const { values, setFieldValue, errors } = useFormikContext<{
    address1: string;
    address2: string;
    city: string;
    zipcode: string;
    country: string;
  }>();

  const [countryList, setCountryList] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let queryString = `?sort_by=code&order=asc`;

        const response = await getCountriesByInfo(queryString);
        const formattedData = response?.data?.countries.map(
          (category: any) => ({
            label: category.name,
            value: category.code,
          })
        );
        setCountryList(formattedData || []);
      } catch (error) {
        console.error("Error validating token:", error);
      }
    };

    fetchCountries();
  }, []);

  return (
    <div>
      <Paragraph text20 className="mb-4 font-medium">
        What’s Your Address?
      </Paragraph>

      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Address line 1<span className="text-site-red">*</span>
        </FormLabel>
        <InputType
          name="address1"
          placeholder="Enter address line 1"
          value={values.address1}
        />
      </div>
      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Address line 2<span className="text-site-red">*</span>
        </FormLabel>
        <InputType
          name="address2"
          placeholder="Enter address line 2"
          value={values.address2}
        />
      </div>
      <div className="grid grid-cols-12 md:gap-6 ">
        <div className="md:col-span-6 col-span-12">
          <div className="form-group md:mb-6 mb-4">
            <FormLabel>
              City<span className="text-site-red">*</span>
            </FormLabel>
            <InputType
              type="text"
              name="city"
              placeholder="Enter city"
              value={values.city}
            />
          </div>
        </div>
        <div className="md:col-span-6 col-span-12">
          <div className="form-group md:mb-6 mb-4">
            <FormLabel>
              Post code<span className="text-site-red">*</span>
            </FormLabel>
            <InputType
              type="text"
              name="zipcode"
              placeholder="Enter post code"
              value={values.zipcode}
            />
          </div>
        </div>
      </div>
      <div className="form-group md:mb-6 mb-4">
        <FormLabel>
          Country<span className="text-site-red">*</span>
        </FormLabel>

        <SelectType
          fullWidth
          options={countryList}
          placeholder="Select country"
          onChange={(option) => setFieldValue("country", option?.value)}
          value={
            countryList?.find((option) => option?.value === values?.country) ||
            ""
          }
          error={errors?.country}
        ></SelectType>
      </div>
    </div>
  );
}

export default WhatsAddress;
