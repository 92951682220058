import React, { useState, useRef, useEffect } from "react";

// Define the type for a tab item
interface Tab {
  id: string;
  name: string;
  content: React.ReactNode; // Can be a React component or any other valid React node
}

// Define the props interface
interface SlidingTabBarProps {
  tabs: Tab[];
  underlineColor?: string;
  TextColor?: string;
  borderedTab?: boolean;
}

// Define the component function
const SlidingTabBar = (props: SlidingTabBarProps) => {
  const { tabs, underlineColor, TextColor, borderedTab } = props;
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState<number>(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState<number>(0);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
      if (currentTab) {
        setTabUnderlineLeft(currentTab.offsetLeft);
        setTabUnderlineWidth(currentTab.clientWidth);
      }
    };

    setTabPosition();
  }, [activeTabIndex, tabs]);

  return (
    <div className="relative flex flex-col">
      <div
        className={`relative bg-light-grey backdrop-blur-sm flew-row ${
          borderedTab
            ? "rounded-none flex w-full xl:px-30 md:px-5 sm:px-4 px-3 border-b border-medium-grey"
            : "rounded-[50px] inline-flex w-max p-1"
        }`}
      >
        <span
          className={`absolute bottom-0 -z-10 flex overflow-hidden rounded-3xl transition-all duration-300 ${
            borderedTab ? "bottom-0" : "top-0 py-[4px]"
          }`}
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        >
          <span
            className={`w-full rounded-3xl ${
              borderedTab ? "h-[2px]" : "h-full "
            }`}
            style={{
              backgroundColor: underlineColor
                ? underlineColor
                : borderedTab
                ? "#121111"
                : "#FFD45A",
            }}
          />
        </span>
        {tabs?.map((tab, index) => {
          const isActive = activeTabIndex === index;

          return (
            <button
              key={tab.id}
              ref={(el) => (tabsRef.current[index] = el)}
              className={`my-auto cursor-pointer select-none rounded-full  text-center sm:text-[16px] text-sm
              leading-[15px]  ${
                borderedTab
                  ? "py-3.5 md:px-4 px-3 font-normal"
                  : "py-[10px] px-7 font-medium "
              }`}
              onClick={() => setActiveTabIndex(index)}
              style={{ color: TextColor }}
            >
              {tab.name}
            </button>
          );
        })}
      </div>
      <div className={`${borderedTab ? "mt-30" : "mt-10"} w-full`}>
        {tabs[activeTabIndex].content}{" "}
        {/* Render the content of the active tab */}
      </div>
    </div>
  );
};

export default SlidingTabBar;
