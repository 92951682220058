import React from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import SelectType from "../../../components/core/form-components/SelectType";
import RadioButton from "../../../components/core/form-components/RadioButton";
import Checkbox from "../../../components/core/form-components/Checkbox";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";

function SearchFilter() {
  const artTagOptions = [
    {
      label: "Tag 1",
      value: "tag-1",
    },
    {
      label: "Tag 2",
      value: "tag-2",
    },
  ];
  const handleSelect = () => {
    console.log("select");
  };
  return (
    <div>
      <div className="border-b border-b-light-grey pb-4 mb-4">
        <Paragraph text16 className="font-medium mb-4">
          Artag
        </Paragraph>
        <SelectType
          options={artTagOptions}
          onChange={handleSelect}
          isSearchable
          fullWidth
          placeholder="Select tag "
        />
      </div>
      <div className="border-b border-b-light-grey pb-4 mb-4">
        <Paragraph text16 className="font-medium mb-4">
          Category
        </Paragraph>
        <ul className="flex flex-col gap-3">
          <li className="flex">
            <RadioButton name="category" id="numerique">
              Art numerique
            </RadioButton>
          </li>
          <li className="flex">
            <RadioButton name="category" id="autre">
              Autre
            </RadioButton>
          </li>
        </ul>
      </div>
      <div className="border-b border-b-light-grey pb-4 mb-4">
        <Paragraph text16 className="font-medium mb-4">
          Colourimetry
        </Paragraph>
        <Paragraph text14 className="!font-normal !text-dark-grey mb-4">
          Note: You can select a maximum of two colors.
        </Paragraph>
        <ul className="flex flex-col gap-3 mb-4">
          <li className="flex">
            <Checkbox name="colourimetry" id="black" w18>
              <div className="flex items-center gap-2">
                <span className="w-5 h-5 rounded-full block bg-black"></span>{" "}
                Black
              </div>
            </Checkbox>
          </li>
          <li className="flex">
            <Checkbox name="colourimetry" id="black" w18>
              <div className="flex items-center gap-2">
                <span className="w-5 h-5 rounded-full block bg-[#4F83E8]"></span>{" "}
                Blue
              </div>
            </Checkbox>
          </li>
        </ul>
        <span className="text-site-red text-sm leading-[14px]">+5 More</span>
      </div>
      <div className="border-b border-b-light-grey pb-4 mb-4">
        <Paragraph text16 className="font-medium mb-4">
          Price
        </Paragraph>
      </div>
      <div className="pb-4 flex items-center gap-3 justify-between">
        <Paragraph text16 className="font-medium">
          Framed artwork
        </Paragraph>
        <SwitchToggle isChecked></SwitchToggle>
      </div>
    </div>
  );
}

export default SearchFilter;
