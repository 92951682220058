import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import GuideImgOne from "../../../assets/images/guide_img_1.png";
import GuideImgTwo from "../../../assets/images/guide_img_2.png";
import GuideImgThree from "../../../assets/images/guide_img_3.png";
import GuideImgFour from "../../../assets/images/guide_img_4.png";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";

interface GuideModalProps {
  onClose?: () => void;
  open: boolean;
}

function GuideModal({ open, onClose }: GuideModalProps) {
  // let sliderRef = useRef<HTMLInputElement>(null);
  const sliderRef = useRef<Slider | null>(null);
  // const next = () => {
  //   sliderRef.slickNext();
  // };
  const next = () => {
    sliderRef.current?.slickNext();
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: React.ReactElement<any>) => (
      <div
        style={{
          maxWidth: "320px",
          padding: "0",
          position: "static",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "100%",
          background: "#121111",
          opacity: "20%",
        }}
      ></div>
    ),
  };
  return (
    <div className="overflow-hidden guide-slider">
      <Slider ref={sliderRef} {...settings}>
        <div className="">
          <div className="flex sm:flex-row flex-col items-center gap-6">
            <div className="">
              <img src={GuideImgOne} alt="guide-img" width={302} height={302} />
            </div>
            <div className="text-center max-w-[246px]">
              <Paragraph text20 className="font-medium mb-2.5 leading-6">
                ArtGapi offers a platform for you to sell your artwork.
              </Paragraph>
              <Paragraph text20 className="!text-[#737373] !leading-6">
                Your Ads post will look like this and Ads post is available in
                image & video format
              </Paragraph>
            </div>
          </div>
        </div>
        <div>
          <div className="flex sm:flex-row flex-col items-center gap-6">
            <div className="">
              <img src={GuideImgTwo} alt="guide-img" width={302} height={302} />
            </div>
            <div className="text-center max-w-[246px]">
              <Paragraph text20 className="font-medium mb-2.5 leading-6">
                Provide the essential details of your artwork to help us
                showcase it.
              </Paragraph>
              <Paragraph text20 className="!text-[#737373] !leading-6">
                Share essential details to enhance its presentation and engage
                your audience with it.
              </Paragraph>
            </div>
          </div>
        </div>
        <div>
          <div className="flex sm:flex-row flex-col items-center gap-6">
            <div className="">
              <img
                src={GuideImgThree}
                alt="guide-img"
                width={302}
                height={302}
              />
            </div>
            <div className="text-center max-w-[246px]">
              <Paragraph text20 className="font-medium mb-2.5 leading-6">
                Pay through your stripe account
              </Paragraph>
              <Paragraph text20 className="!text-[#737373] !leading-6">
                Easily complete your purchase: Pay securely and swiftly through
                your Stripe account
              </Paragraph>
            </div>
          </div>
        </div>
        <div>
          <div className="flex sm:flex-row flex-col items-center gap-6">
            <div className="">
              <img
                src={GuideImgFour}
                alt="guide-img"
                width={302}
                height={302}
              />
            </div>
            <div className="text-center max-w-[246px]">
              <Paragraph text20 className="font-medium mb-2.5 leading-6">
                Congratulations!
              </Paragraph>
              <Paragraph text20 className="!text-[#737373] !leading-6">
                Your artwork is now live and available for viewing!
              </Paragraph>
            </div>
          </div>
        </div>
      </Slider>
      <div className="flex items-center justify-between gap-3 mt-5">
        <Paragraph onClick={onClose} text18 className="font-semibold">
          Skip
        </Paragraph>
        <Button primary onClick={next}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default GuideModal;
