import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/black_logo.svg";
import Favicon from "../../assets/images/artgapi_logo.svg";
import { Link, NavLink } from "react-router-dom";
import { pathRoute } from "../../routes/pathRoute";
import Paragraph from "../core/typography/Paragraph";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../components/language-translation/LanguageTranslation";

interface sidebarProps {
  sideBarOpen: boolean;
  sideBarMobileOpen: boolean;
  handleMobileSidebar: () => void;
}

function Sidebar({
  sideBarOpen,
  sideBarMobileOpen,
  handleMobileSidebar,
}: sidebarProps) {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div
      className={`${
        windowWidth < 992 && "fixed top-0 z-50 bg-white w-[254px] p-4"
      } ${
        sideBarMobileOpen ? "left-0" : "-left-full"
      } duration-500 transition-all ease-in-out`}
    >
      <div className="text-center flex items-center lg:justify-center justify-between">
        <img
          src={sideBarOpen ? Favicon : Logo}
          alt="logo"
          title="ArtGapi"
          width={sideBarOpen ? "auto" : 122}
          height={sideBarOpen ? 50 : 50}
          className="inline-block transition-all ease-in-out"
        />
        {windowWidth < 992 && (
          <button
            className="lg:hidden flex items-center justify-center z-30 relative w-8 h-8 text-white focus:outline-none"
            onClick={() => {
              handleMobileSidebar();
              setNavbarOpen(!navbarOpen);
            }}
          >
            <span
              className={`absolute h-0.5 w-5 bg-site-black transform transition duration-300 ease-in-out ${
                sideBarMobileOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
              }`}
            ></span>
            <span
              className={`absolute h-0.5 bg-site-black transform transition-all duration-200 ease-in-out ${
                sideBarMobileOpen
                  ? "w-0 opacity-50"
                  : "w-5 delay-200 opacity-100"
              }`}
            ></span>
            <span
              className={`absolute h-0.5 w-5 bg-site-black transform transition duration-300 ease-in-out ${
                sideBarMobileOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
              }`}
            ></span>
          </button>
        )}
      </div>
      <nav className={` ${sideBarOpen && "text-center"} mt-30`}>
        <ul className="lg:h-[calc(100vh-170px)] h-[calc(100vh-162px)] overflow-y-auto">
          <li
            className={`transition-all ease-in-out delay-700 duration-500 ${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            }`}
          >
            <NavLink
              to={pathRoute.auth.social}
              className={({ isActive }) =>
                `flex items-center ${
                  !sideBarMobileOpen && sideBarOpen
                    ? "w-10 h-10 justify-center"
                    : "gap-[10px] py-[6px] px-4"
                }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                  isActive
                    ? "bg-site-black hover:bg-site-black"
                    : "bg-white lg:hover:bg-site-yellow"
                }`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9713 22.896V20.144C11.9713 19.4416 12.545 18.8721 13.2526 18.8721H15.8393C16.1792 18.8721 16.5051 19.0061 16.7454 19.2446C16.9856 19.4832 17.1206 19.8067 17.1206 20.144V22.896C17.1185 23.1881 17.2339 23.4689 17.4411 23.6762C17.6484 23.8834 17.9304 24 18.2246 24H19.9894C20.8137 24.0021 21.6049 23.6785 22.1885 23.1007C22.772 22.5229 23.1 21.7383 23.1 20.92V13.0802C23.1 12.4192 22.8049 11.7923 22.2942 11.3682L16.2906 6.60828C15.2463 5.77371 13.75 5.80065 12.7369 6.67228L6.87031 11.3682C6.33547 11.7798 6.0158 12.4086 6 13.0802V20.912C6 22.6175 7.39264 24 9.11056 24H10.8351C11.4461 24 11.9427 23.5106 11.9471 22.904L11.9713 22.896Z"
                      fill={isActive ? "#FFFFFF" : "#121111"} // Change fill color based on active state
                    />
                  </svg>
                  {!sideBarOpen && (
                    <span
                      className={`text-[18px] leading-[17px] font-normal ${
                        isActive ? "text-white" : "text-site-black"
                      }`}
                    >
                      Social
                    </span>
                  )}
                </>
              )}
            </NavLink>
          </li>
          <li
            className={`transition-all ease-in-out delay-700 duration-500 ${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            }`}
          >
            <NavLink
              to={pathRoute.website.masterClass}
              className={({ isActive }) =>
                `flex items-center ${
                  !sideBarMobileOpen && sideBarOpen
                    ? "w-10 h-10 justify-center"
                    : "gap-[10px] py-[6px] px-4"
                }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                  isActive
                    ? "bg-site-black hover:bg-site-black"
                    : "bg-white lg:hover:bg-site-yellow"
                }`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24.8458 21.4603C24.8458 20.7874 24.5781 20.1413 24.1027 19.665C23.6264 19.1896 22.9802 18.9219 22.3073 18.9219C21.3076 18.9219 20.0762 18.9219 19.0765 18.9219C18.4036 18.9219 17.7575 19.1896 17.2812 19.665C16.8058 20.1413 16.5381 20.7874 16.5381 21.4603V22.3834C16.5381 23.276 17.2618 23.9988 18.1535 23.9988H23.2304C24.1221 23.9988 24.8458 23.276 24.8458 22.3834V21.4603Z"
                      fill={isActive ? "#FFFFFF" : "#121111"}
                    />
                    <path
                      d="M20.6924 18.4609C22.3492 18.4609 23.6924 17.1178 23.6924 15.4609C23.6924 13.8041 22.3492 12.4609 20.6924 12.4609C19.0355 12.4609 17.6924 13.8041 17.6924 15.4609C17.6924 17.1178 19.0355 18.4609 20.6924 18.4609Z"
                      fill={isActive ? "#FFFFFF" : "#121111"}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.6228 21.2308C15.6791 20.3972 16.0345 19.608 16.6289 19.0135C16.9898 18.6526 17.4228 18.3803 17.8926 18.2086C17.1985 17.5006 16.7692 16.5314 16.7692 15.4615C16.7692 13.296 18.5268 11.5385 20.6923 11.5385C22.8578 11.5385 24.6154 13.296 24.6154 15.4615C24.6154 16.5314 24.1862 17.5006 23.492 18.2086C23.9618 18.3803 24.3948 18.6526 24.7557 19.0135C24.7862 19.0449 24.8166 19.0763 24.8462 19.1077V7.61538C24.8462 7.18708 24.6763 6.77631 24.3726 6.47354C24.0698 6.16985 23.6591 6 23.2308 6C20.0794 6 9.76677 6 6.61538 6C6.18708 6 5.77631 6.16985 5.47354 6.47354C5.16985 6.77631 5 7.18708 5 7.61538V19.6154C5 20.5071 5.72277 21.2308 6.61538 21.2308H15.6228ZM8.92308 15.6923H14.9231C15.3052 15.6923 15.6154 15.3822 15.6154 15C15.6154 14.6178 15.3052 14.3077 14.9231 14.3077H8.92308C8.54092 14.3077 8.23077 14.6178 8.23077 15C8.23077 15.3822 8.54092 15.6923 8.92308 15.6923ZM8.92308 12H12.6154C12.9975 12 13.3077 11.6898 13.3077 11.3077C13.3077 10.9255 12.9975 10.6154 12.6154 10.6154H8.92308C8.54092 10.6154 8.23077 10.9255 8.23077 11.3077C8.23077 11.6898 8.54092 12 8.92308 12Z"
                      fill={isActive ? "#FFFFFF" : "#121111"}
                    />
                  </svg>
                  {!sideBarOpen && (
                    <span
                      className={`text-[18px] leading-[17px] font-normal ${
                        isActive
                          ? "text-white hover:bg-site-black"
                          : "text-site-black"
                      }`}
                    >
                      Masterclass
                    </span>
                  )}
                </>
              )}
            </NavLink>
          </li>
          <li
            className={`transition-all ease-in-out delay-700 duration-500 ${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            }`}
          >
            <NavLink
              to={pathRoute.website.opportunities}
              className={({ isActive }) =>
                `flex items-center ${
                  !sideBarMobileOpen && sideBarOpen
                    ? "w-10 h-10 justify-center"
                    : "gap-[10px] py-[6px] px-4"
                }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                  isActive
                    ? "bg-site-black hover:bg-site-black"
                    : "bg-white lg:hover:bg-site-yellow"
                }`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.917 15.0328C15.6772 15.6367 15.3762 16.0272 15.0459 16.1739C15.1943 14.404 15.7266 13.7736 15.9516 13.6791C15.9727 13.6703 15.9866 13.6669 15.9965 13.6669C16.0099 13.6669 16.016 13.673 16.0231 13.68C16.1431 13.7976 16.2205 14.269 15.917 15.0328ZM9.87965 16.8841C10.6553 17.9662 11.3906 18.9929 11.4913 20.4833H14.3055V17.1649C14.3055 17.0657 14.3065 16.9685 14.3085 16.8732C13.3814 16.5879 12.6057 15.428 12.3714 13.923C12.3416 13.7317 12.4726 13.5524 12.6639 13.5227C12.8551 13.4932 13.0345 13.6238 13.0643 13.8152C13.2555 15.0435 13.8157 15.8523 14.3462 16.131C14.5061 14.2128 15.1098 13.2719 15.6799 13.0325C15.9799 12.9065 16.2918 12.9612 16.514 13.1792C16.9108 13.5683 16.9317 14.3778 16.5686 15.2917C16.1059 16.4568 15.4889 16.8271 15.009 16.9165C15.0075 16.9973 15.0067 17.0802 15.0067 17.1649V20.4832H17.8209C17.9217 18.9929 18.657 17.9662 19.4326 16.8841C20.3566 15.595 21.3121 14.262 21.3121 11.9261C21.3121 7.85448 17.8617 6 14.656 6C11.4503 6 8 7.85448 8 11.9261C8 14.262 8.95571 15.595 9.87965 16.8841ZM13.133 23.4069C13.133 23.7339 13.399 24 13.7261 24H15.5865C15.9135 24 16.1796 23.7339 16.1796 23.4069V23.072H13.133L13.133 23.4069ZM11.5035 21.4883C11.5035 21.9748 11.8994 22.3707 12.386 22.3707H16.9264C17.413 22.3707 17.8089 21.9748 17.8089 21.4883V21.1846H11.5035L11.5035 21.4883Z"
                      fill={isActive ? "#FFFFFF" : "#121111"}
                    />
                  </svg>
                  {!sideBarOpen && (
                    <span
                      className={`text-[18px] leading-[17px] font-normal ${
                        isActive ? "text-white" : "text-site-black"
                      }`}
                    >
                      Opportunities
                    </span>
                  )}
                </>
              )}
            </NavLink>
          </li>
          <li
            className={`transition-all ease-in-out delay-700 duration-500 ${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            }`}
          >
            <NavLink
              to={"/create-post"}
              className={({ isActive }) =>
                `flex items-center ${
                  !sideBarMobileOpen && sideBarOpen
                    ? "w-10 h-10 justify-center"
                    : "gap-[10px] py-[6px] px-4"
                }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                  isActive
                    ? "bg-site-black hover:bg-site-black"
                    : "bg-white lg:hover:bg-site-yellow"
                }`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.875 13.875H16.125V7.125C16.125 6.82663 16.0065 6.54048 15.7955 6.32951C15.5845 6.11853 15.2984 6 15 6C14.7016 6 14.4155 6.11853 14.2045 6.32951C13.9935 6.54048 13.875 6.82663 13.875 7.125V13.875H7.125C6.82663 13.875 6.54048 13.9935 6.32951 14.2045C6.11853 14.4155 6 14.7016 6 15C6 15.2984 6.11853 15.5845 6.32951 15.7955C6.54048 16.0065 6.82663 16.125 7.125 16.125H13.875V22.875C13.875 23.1734 13.9935 23.4595 14.2045 23.6705C14.4155 23.8815 14.7016 24 15 24C15.2984 24 15.5845 23.8815 15.7955 23.6705C16.0065 23.4595 16.125 23.1734 16.125 22.875V16.125H22.875C23.1734 16.125 23.4595 16.0065 23.6705 15.7955C23.8815 15.5845 24 15.2984 24 15C24 14.7016 23.8815 14.4155 23.6705 14.2045C23.4595 13.9935 23.1734 13.875 22.875 13.875Z"
                      fill={isActive ? "#FFFFFF" : "#121111"}
                    />
                  </svg>
                  {!sideBarOpen && (
                    <span
                      className={`text-[18px] leading-[17px] font-normal ${
                        isActive ? "text-white" : "text-site-black"
                      }`}
                    >
                      Create Post
                    </span>
                  )}
                </>
              )}
            </NavLink>
          </li>
          <li
            className={`transition-all ease-in-out delay-700 duration-500 ${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            }`}
          >
            {windowWidth > 991 ? (
              <NavLink
                to={pathRoute?.website?.settings}
                className={({ isActive }) =>
                  `flex items-center ${
                    !sideBarMobileOpen && sideBarOpen
                      ? "w-10 h-10 justify-center"
                      : "gap-[10px] py-[6px] px-4"
                  }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                    isActive
                      ? "bg-site-black hover:bg-site-black"
                      : "bg-white lg:hover:bg-site-yellow"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5 24C7.5 24 6 24 6 22.5C6 21 7.5 16.5 15 16.5C22.5 16.5 24 21 24 22.5C24 24 22.5 24 22.5 24H7.5Z"
                        fill={isActive ? "#FFFFFF" : "#121111"}
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 15C16.1935 15 17.3381 14.5259 18.182 13.682C19.0259 12.8381 19.5 11.6935 19.5 10.5C19.5 9.30653 19.0259 8.16193 18.182 7.31802C17.3381 6.47411 16.1935 6 15 6C13.8065 6 12.6619 6.47411 11.818 7.31802C10.9741 8.16193 10.5 9.30653 10.5 10.5C10.5 11.6935 10.9741 12.8381 11.818 13.682C12.6619 14.5259 13.8065 15 15 15Z"
                        fill={isActive ? "#FFFFFF" : "#121111"}
                      />
                    </svg>
                    {!sideBarOpen && (
                      <span
                        className={`text-[18px] leading-[17px] font-normal ${
                          isActive ? "text-white" : "text-site-black"
                        }`}
                      >
                        Account
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            ) : (
              <>
                <button
                  onClick={toggleDropdown}
                  className={`flex items-center w-full mb-3 gap-[10px] py-[6px] px-4 rounded-[20px] transition-all ease-in-out duration-500 ${
                    dropdownOpen
                      ? "bg-site-black hover:bg-site-black"
                      : "bg-white lg:hover:bg-site-yellow"
                  }`}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.5 24C7.5 24 6 24 6 22.5C6 21 7.5 16.5 15 16.5C22.5 16.5 24 21 24 22.5C24 24 22.5 24 22.5 24H7.5Z"
                      fill={dropdownOpen ? "#FFFFFF" : "#121111"}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 15C16.1935 15 17.3381 14.5259 18.182 13.682C19.0259 12.8381 19.5 11.6935 19.5 10.5C19.5 9.30653 19.0259 8.16193 18.182 7.31802C17.3381 6.47411 16.1935 6 15 6C13.8065 6 12.6619 6.47411 11.818 7.31802C10.9741 8.16193 10.5 9.30653 10.5 10.5C10.5 11.6935 10.9741 12.8381 11.818 13.682C12.6619 14.5259 13.8065 15 15 15Z"
                      fill={dropdownOpen ? "#FFFFFF" : "#121111"}
                    />
                  </svg>
                  <span
                    className={`text-[18px] leading-[17px] font-normal ${
                      dropdownOpen ? "text-white" : "text-site-black"
                    }`}
                  >
                    Account
                  </span>
                  <IoIosArrowDown
                    size={16}
                    color={dropdownOpen ? "#fff" : "#121111"}
                    className={`ml-auto transition-transform ${
                      dropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>

                <div
                  className={`${
                    dropdownOpen ? "h-[336px]" : "h-0"
                  } overflow-hidden transition-all ease-in-out duration-700`}
                >
                  <ul className="pl-6 pt-2">
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.myPurchase}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.703 12.4829L16.3252 6.01562H13.2755V8.91967H11.9865V6.01562H7.0219V8.91967H5.73289V6.01562H2.6831L2.00573 17.6112C1.95301 18.5141 2.26572 19.3736 2.88629 20.0315C3.50686 20.6894 4.34669 21.0517 5.25106 21.0517H12.4012C11.984 20.2393 11.7484 19.3191 11.7484 18.3448C11.7484 15.4041 13.8948 12.9557 16.703 12.4829Z"
                                fill="#7F7F80"
                              />
                              <path
                                d="M7.02143 4.77125C7.02143 3.40254 8.13496 2.28901 9.50367 2.28901C10.8724 2.28901 11.986 3.40254 11.986 4.77125V6.0151H13.275V4.77125C13.275 2.69178 11.5832 1 9.50367 1C7.42416 1 5.73242 2.69178 5.73242 4.77125V6.0151H7.02143V4.77125Z"
                                fill="#7F7F80"
                              />
                              <path
                                d="M22.3486 18.3452C22.3486 15.778 20.2601 13.6895 17.6929 13.6895C15.1257 13.6895 13.0371 15.778 13.0371 18.3452C13.0371 20.9124 15.1257 23.0009 17.6929 23.0009C20.2601 23.0009 22.3486 20.9124 22.3486 18.3452ZM16.9958 20.6254L14.9023 18.2576L15.8679 17.4038L17.0374 18.7265L19.4594 16.2178L20.3867 17.113L16.9958 20.6254Z"
                                fill="#7F7F80"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "my_purchase",
                                languageCode: langId || "fr",
                              }) || "My Purchase"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.mySales}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 1C5.9251 1 1 5.9251 1 12C1 18.0749 5.9251 23 12 23C18.0749 23 23 18.0749 23 12C23 5.9251 18.0749 1 12 1ZM15.8387 17.5724C15.5082 17.7218 15.1642 17.8486 14.8111 17.9436C13.4712 18.3148 12.1313 18.3284 10.7959 17.8938C9.18436 17.3733 8.11605 16.2733 7.47778 14.7342C7.35556 14.4354 7.27407 14.123 7.17901 13.8107H5.91605C5.70329 13.8107 5.5358 13.6387 5.5358 13.4305V12.5794C5.5358 12.3667 5.70782 12.1992 5.91605 12.1992H6.96173C6.96173 11.9638 6.96173 11.742 6.96173 11.5066H5.91605C5.70329 11.5066 5.5358 11.3346 5.5358 11.1263V10.2753C5.5358 10.0626 5.70782 9.89506 5.91605 9.89506H7.24691L7.29218 9.76379C7.64979 8.62757 8.26543 7.65432 9.21152 6.92099C9.96296 6.33704 10.8321 6.01564 11.7646 5.86626C13.0909 5.6535 14.3765 5.83457 15.6214 6.32346C15.7029 6.35514 15.7798 6.39136 15.8568 6.43663C16.1556 6.61317 16.2325 6.83498 16.1012 7.15638C15.9609 7.49136 15.8206 7.83086 15.6667 8.16132C15.5354 8.4465 15.3543 8.53704 15.0465 8.46914C14.6255 8.37407 14.2136 8.2428 13.7926 8.15679C12.9053 7.97572 12.0226 7.96667 11.158 8.30617C10.4156 8.60041 9.96296 9.16625 9.65514 9.87243C9.65062 9.88148 9.65062 9.89054 9.65062 9.89959H12.2354C12.4481 9.89959 12.6156 10.0716 12.6156 10.2798V11.1309C12.6156 11.3436 12.4436 11.5111 12.2354 11.5111H9.28395C9.27942 11.7374 9.28395 11.9638 9.28395 12.2037H12.2399C12.4527 12.2037 12.6202 12.3757 12.6202 12.584V13.435C12.6202 13.6477 12.4481 13.8152 12.2399 13.8152H9.54198C9.8679 14.7477 10.3975 15.4675 11.3934 15.7798C12.1901 16.0288 12.9914 16.0152 13.7971 15.8477C14.2136 15.7617 14.621 15.635 15.0374 15.5399C15.3634 15.4675 15.5399 15.5535 15.6757 15.8523C15.8296 16.1872 15.9745 16.5267 16.1103 16.8708C16.237 17.1786 16.1556 17.4276 15.8387 17.5724Z"
                                fill="#7F7F80"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "my_sales",
                                languageCode: langId || "fr",
                              }) || "My Sales"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.myMasterclass}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24.8458 21.4603C24.8458 20.7874 24.5781 20.1413 24.1027 19.665C23.6264 19.1896 22.9802 18.9219 22.3073 18.9219C21.3076 18.9219 20.0762 18.9219 19.0765 18.9219C18.4036 18.9219 17.7575 19.1896 17.2812 19.665C16.8058 20.1413 16.5381 20.7874 16.5381 21.4603V22.3834C16.5381 23.276 17.2618 23.9988 18.1535 23.9988H23.2304C24.1221 23.9988 24.8458 23.276 24.8458 22.3834V21.4603Z"
                                fill="#7F7F80"
                              />
                              <path
                                d="M20.6924 18.4609C22.3492 18.4609 23.6924 17.1178 23.6924 15.4609C23.6924 13.8041 22.3492 12.4609 20.6924 12.4609C19.0355 12.4609 17.6924 13.8041 17.6924 15.4609C17.6924 17.1178 19.0355 18.4609 20.6924 18.4609Z"
                                fill="#7F7F80"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15.6228 21.2308C15.6791 20.3972 16.0345 19.608 16.6289 19.0135C16.9898 18.6526 17.4228 18.3803 17.8926 18.2086C17.1985 17.5006 16.7692 16.5314 16.7692 15.4615C16.7692 13.296 18.5268 11.5385 20.6923 11.5385C22.8578 11.5385 24.6154 13.296 24.6154 15.4615C24.6154 16.5314 24.1862 17.5006 23.492 18.2086C23.9618 18.3803 24.3948 18.6526 24.7557 19.0135C24.7862 19.0449 24.8166 19.0763 24.8462 19.1077V7.61538C24.8462 7.18708 24.6763 6.77631 24.3726 6.47354C24.0698 6.16985 23.6591 6 23.2308 6C20.0794 6 9.76677 6 6.61538 6C6.18708 6 5.77631 6.16985 5.47354 6.47354C5.16985 6.77631 5 7.18708 5 7.61538V19.6154C5 20.5071 5.72277 21.2308 6.61538 21.2308H15.6228ZM8.92308 15.6923H14.9231C15.3052 15.6923 15.6154 15.3822 15.6154 15C15.6154 14.6178 15.3052 14.3077 14.9231 14.3077H8.92308C8.54092 14.3077 8.23077 14.6178 8.23077 15C8.23077 15.3822 8.54092 15.6923 8.92308 15.6923ZM8.92308 12H12.6154C12.9975 12 13.3077 11.6898 13.3077 11.3077C13.3077 10.9255 12.9975 10.6154 12.6154 10.6154H8.92308C8.54092 10.6154 8.23077 10.9255 8.23077 11.3077C8.23077 11.6898 8.54092 12 8.92308 12Z"
                                fill="#7F7F80"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "my_masterclass",
                                languageCode: langId || "fr",
                              }) || "My Masterclass"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.myTransactions}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM7 9H14.59L14.29 8.71C14.1017 8.5217 13.9959 8.2663 13.9959 8C13.9959 7.86814 14.0219 7.73757 14.0723 7.61575C14.1228 7.49393 14.1968 7.38324 14.29 7.29C14.3832 7.19676 14.4939 7.1228 14.6158 7.07234C14.7376 7.02188 14.8681 6.99591 15 6.99591C15.2663 6.99591 15.5217 7.1017 15.71 7.29L17.71 9.29C17.8488 9.43062 17.9428 9.6092 17.9801 9.80319C18.0175 9.99718 17.9966 10.1979 17.92 10.38C17.845 10.5626 17.7176 10.7189 17.5539 10.8293C17.3901 10.9396 17.1974 10.999 17 11H7C6.73479 11 6.48043 10.8946 6.2929 10.7071C6.10536 10.5196 6 10.2652 6 10C6 9.73478 6.10536 9.48043 6.2929 9.29289C6.48043 9.10536 6.73479 9 7 9ZM17 15H9.41L9.71 15.29C9.80373 15.383 9.87813 15.4936 9.9289 15.6154C9.97966 15.7373 10.0058 15.868 10.0058 16C10.0058 16.132 9.97966 16.2627 9.9289 16.3846C9.87813 16.5064 9.80373 16.617 9.71 16.71C9.61704 16.8037 9.50644 16.8781 9.38458 16.9289C9.26272 16.9797 9.13202 17.0058 9 17.0058C8.86799 17.0058 8.73729 16.9797 8.61543 16.9289C8.49357 16.8781 8.38297 16.8037 8.29 16.71L6.29 14.71C6.15125 14.5694 6.05725 14.3908 6.01988 14.1968C5.9825 14.0028 6.00343 13.8021 6.08 13.62C6.15503 13.4374 6.28242 13.2811 6.44614 13.1707C6.60986 13.0604 6.80258 13.001 7 13H17C17.2652 13 17.5196 13.1054 17.7071 13.2929C17.8946 13.4804 18 13.7348 18 14C18 14.2652 17.8946 14.5196 17.7071 14.7071C17.5196 14.8946 17.2652 15 17 15Z"
                                fill="#7F7F80"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "my_transaction",
                                languageCode: langId || "fr",
                              }) || "My Transaction"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.paymentMethods}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.5807 10.5811C15.1416 10.5811 13.1621 12.5606 13.1621 14.9997C13.1621 17.4387 15.1416 19.4183 17.5807 19.4183C20.0198 19.4183 21.9993 17.4387 21.9993 14.9997C21.9993 12.5606 20.0198 10.5811 17.5807 10.5811ZM15.7714 15.9578L16.7016 16.888C16.843 17.0294 17.0365 17.1029 17.2356 17.0918C17.4337 17.0797 17.6189 16.9848 17.7416 16.8285L19.523 14.5829C19.7621 14.2806 19.7109 13.8415 19.4096 13.6025C19.1082 13.3634 18.6682 13.4136 18.4291 13.7159L17.1351 15.3485L16.7584 14.9718C16.4858 14.6992 16.044 14.6992 15.7714 14.9718C15.4998 15.2434 15.4998 15.6862 15.7714 15.9578Z"
                                fill="#7F7F80"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15.3981 10.1163H2V15.9302C2 16.6084 2.26977 17.2595 2.74884 17.7395C3.22884 18.2186 3.88 18.4884 4.55814 18.4884H13.5274C12.7209 17.5516 12.2326 16.3321 12.2326 15C12.2326 12.8251 13.533 10.9526 15.3981 10.1163ZM8.28 14.3023H5.48837C5.10326 14.3023 4.7907 14.6149 4.7907 15C4.7907 15.3851 5.10326 15.6977 5.48837 15.6977H8.28C8.66512 15.6977 8.97767 15.3851 8.97767 15C8.97767 14.6149 8.66512 14.3023 8.28 14.3023ZM2 8.72093H20.1395V7.55814C20.1395 6.88 19.8698 6.22884 19.3907 5.74884C18.9107 5.26977 18.2595 5 17.5814 5C14.36 5 7.77953 5 4.55814 5C3.88 5 3.22884 5.26977 2.74884 5.74884C2.26977 6.22884 2 6.88 2 7.55814V8.72093Z"
                                fill="#7F7F80"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "payment_methods",
                                languageCode: langId || "fr",
                              }) || "Payment Methods"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.stripe}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.8389 22H4.16111C2.96758 22 2 21.0325 2 19.8389V4.16111C2 2.96758 2.96758 2 4.16111 2H19.8389C21.0325 2 22 2.96758 22 4.16111V19.8389C22 21.0325 21.0325 22 19.8389 22Z"
                                fill="#7F7F80"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.1542 9.05555C11.1542 8.51979 11.5741 8.31379 12.2697 8.31379C13.267 8.31379 14.5268 8.62967 15.5242 9.19293V5.96489C14.435 5.51163 13.3589 5.33301 12.2697 5.33301C9.60563 5.33301 7.83398 6.78902 7.83398 9.22043C7.83398 13.0116 12.8209 12.4072 12.8209 14.0419C12.8209 14.6737 12.2959 14.8797 11.5611 14.8797C10.4718 14.8797 9.08076 14.4127 7.97837 13.7809V17.05C9.19887 17.5995 10.4324 17.833 11.5611 17.833C14.2906 17.833 16.1673 16.4181 16.1673 13.9594C16.1542 9.86594 11.1542 10.5941 11.1542 9.05555Z"
                                fill="white"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "stripe",
                                languageCode: langId || "fr",
                              }) || "Stripe"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                    <li
                      className={`transition-all ease-in-out delay-700 duration-500 block`}
                    >
                      <NavLink
                        to={pathRoute.website.settings}
                        className={({ isActive }) =>
                          `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                            isActive
                              ? "bg-light-grey hover:bg-light-grey"
                              : "bg-white hover:bg-light-grey"
                          }`
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M21.185 13.5721C20.4951 13.1747 20.0667 12.4343 20.0667 11.639C20.0667 10.844 20.4951 10.1036 21.183 9.7066C21.889 9.30071 22.1827 8.45734 21.881 7.70144C21.5028 6.74661 20.9879 5.85479 20.3543 5.05039C19.8495 4.40995 18.9725 4.24222 18.2665 4.65073C17.5786 5.05039 16.7218 5.0516 16.0319 4.65373C15.344 4.25587 14.9175 3.51426 14.9196 2.71755C14.9196 1.90251 14.3362 1.2261 13.5297 1.10944C12.5159 0.962616 11.4861 0.963432 10.4683 1.11245C9.66378 1.22992 9.0805 1.90594 9.08248 2.71935C9.08248 3.51508 8.65603 4.25626 7.96816 4.65373C7.27823 5.05117 6.4234 5.05035 5.73352 4.65111C5.02753 4.24261 4.15056 4.41115 3.6457 5.0516C3.33192 5.45066 3.04225 5.87728 2.78684 6.32038C2.53138 6.76271 2.30811 7.22632 2.11905 7.69758C1.81531 8.45468 2.10901 9.29925 2.81499 9.70617C3.50492 10.1032 3.93335 10.844 3.93335 11.6389C3.93335 12.4338 3.50492 13.174 2.81701 13.5717C2.11103 13.9776 1.81737 14.8205 2.11905 15.5762C2.49722 16.5316 3.01212 17.4235 3.6457 18.2274C4.15056 18.8679 5.02753 19.0356 5.73352 18.6271C6.42143 18.2274 7.27827 18.2266 7.96816 18.6241C8.65603 19.022 9.08248 19.7636 9.08248 20.5603C9.08046 21.3751 9.66575 22.0515 10.4703 22.1684C10.9752 22.2414 11.4821 22.2778 11.9889 22.2778C12.5038 22.2778 13.0188 22.2404 13.5316 22.1654C14.3362 22.0477 14.9195 21.3723 14.9195 20.5589C14.9175 19.7628 15.3439 19.0215 16.0318 18.6241C16.7217 18.2267 17.5765 18.2275 18.2664 18.6267C18.9724 19.0352 19.8494 18.8671 20.3542 18.2262C20.668 17.827 20.9577 17.4003 21.2131 16.9578C21.4665 16.5156 21.6938 16.0519 21.8809 15.5803C22.1847 14.8232 21.8911 13.9784 21.185 13.5721ZM14.7365 13.2193C14.3161 13.9503 13.6343 14.4734 12.8177 14.6919C12.0031 14.9103 11.1502 14.7983 10.4201 14.3758C8.91153 13.5045 8.39259 11.5681 9.26351 10.0586C9.84683 9.04602 10.9129 8.47923 12.005 8.47923C12.5421 8.47923 13.0832 8.6152 13.58 8.90203C15.0885 9.77316 15.6074 11.7101 14.7365 13.2193Z"
                                fill="#7F7F80"
                              />
                            </svg>

                            <span
                              className={`text-[18px] leading-[17px] font-normal text-site-black`}
                            >
                              {LanguageTranslation({
                                labelName: "settings",
                                languageCode: langId || "fr",
                              }) || "Settings"}
                            </span>
                          </>
                        )}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </li>
        </ul>
        <div
          className={`${
            !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
          } lg:pt-4 pt-2 transition-all ease-in-out delay-700 duration-500 before:h-[1px] before:w-full before:bg-medium-grey before:absolute before:left-0 lg:before:bottom-[73px] before:bottom-[66px]`}
        >
          <NavLink
            to={pathRoute.auth.login}
            className={({ isActive }) =>
              `flex items-center ${
                !sideBarMobileOpen && sideBarOpen
                  ? "w-10 h-10 justify-center"
                  : "gap-[10px] py-[6px] px-4"
              }  lg:mb-4 mb-0 rounded-[20px]  transition-all ease-in-out duration-500 ${
                isActive
                  ? "bg-site-black hover:bg-site-black"
                  : "bg-white lg:hover:bg-site-yellow"
              }`
            }
          >
            {({ isActive }) => (
              <>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7498 6.74991V12.7491C15.7498 12.948 15.6708 13.1388 15.5302 13.2794C15.3895 13.42 15.1988 13.4991 14.9999 13.4991C14.801 13.4991 14.6103 13.42 14.4696 13.2794C14.329 13.1388 14.25 12.948 14.25 12.7491V6.74991C14.25 6.55102 14.329 6.36028 14.4696 6.21964C14.6103 6.07901 14.801 6 14.9999 6C15.1988 6 15.3895 6.07901 15.5302 6.21964C15.6708 6.36028 15.7498 6.55102 15.7498 6.74991Z"
                    fill={isActive ? "#FFFFFF" : "#121111"}
                  />
                  <path
                    d="M15.7498 6.74991V12.7491C15.7498 12.948 15.6708 13.1388 15.5302 13.2794C15.3895 13.42 15.1988 13.4991 14.9999 13.4991C14.801 13.4991 14.6103 13.42 14.4696 13.2794C14.329 13.1388 14.25 12.948 14.25 12.7491V6.74991C14.25 6.55102 14.329 6.36028 14.4696 6.21964C14.6103 6.07901 14.801 6 14.9999 6C15.1988 6 15.3895 6.07901 15.5302 6.21964C15.6708 6.36028 15.7498 6.55102 15.7498 6.74991Z"
                    fill={isActive ? "#FFFFFF" : "#121111"}
                  />
                  <path
                    d="M23.2489 15.7504C23.2493 17.2851 22.8216 18.7894 22.0139 20.0944C21.2061 21.3993 20.0503 22.453 18.6765 23.137C17.3027 23.821 15.7653 24.1082 14.2372 23.9663C12.7091 23.8244 11.2509 23.259 10.0265 22.3337C8.80215 21.4085 7.86017 20.16 7.30651 18.7286C6.75284 17.2973 6.60944 15.7399 6.89244 14.2316C7.17543 12.7232 7.87359 11.3237 8.90843 10.1904C9.94326 9.0571 11.2737 8.23495 12.7502 7.81641V12.7508C12.7502 13.3474 12.9872 13.9197 13.4091 14.3416C13.8311 14.7635 14.4033 15.0005 14.9999 15.0005C15.5966 15.0005 16.1688 14.7635 16.5907 14.3416C17.0126 13.9197 17.2497 13.3474 17.2497 12.7508V7.81641C18.9764 8.30654 20.4963 9.34628 21.5789 10.778C22.6615 12.2098 23.2478 13.9554 23.2489 15.7504Z"
                    fill={isActive ? "#FFFFFF" : "#121111"}
                  />
                </svg>
                {!sideBarOpen && (
                  <span
                    className={`text-[18px] leading-[17px] font-normal ${
                      isActive ? "text-white" : "text-site-black"
                    }`}
                  >
                    Logout
                  </span>
                )}
              </>
            )}
          </NavLink>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
