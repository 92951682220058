import React from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import Art2 from "../../../../assets/images/art2.png";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
function PurchaseDetail() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  return (
    <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
      <div className="grid grid-cols-2 lg:gap-6 md:gap-4 gap-3">
        <div className="block col-span-1">
          <div className="rounded-[20px] overflow-hidden mb-3 text-center flex items-center justify-center bg-light-grey">
            <img src={Art2} alt="" className="w-auto" />
          </div>
        </div>
        <div className="col-span-1">
          <Paragraph text24 className="mb-4">
            {LanguageTranslation({
              labelName: "product_title",
              languageCode: langId || "fr",
            }) || "Des Porcs"}
          </Paragraph>
          <Paragraph text24 className="">
            {LanguageTranslation({
              labelName: "product_price",
              languageCode: langId || "fr",
            }) || "230€"}
          </Paragraph>
          <ul className="mt-4 pt-4 border-t border-b border-medium-grey">
            <li className="flex items-center justify-between gap-3 mb-3">
              <Paragraph text18 className="!font-normal">
                {LanguageTranslation({
                  labelName: "date",
                  languageCode: langId || "fr",
                }) || "Date"}
              </Paragraph>
              <Paragraph text18 className="!font-normal !text-dark-grey">
                {LanguageTranslation({
                  labelName: "purchase_d",
                  languageCode: langId || "fr",
                }) || "02-11-24"}
              </Paragraph>
            </li>
            <li className="flex items-center justify-between gap-3 mb-3">
              <Paragraph text18 className="!font-normal">
                {LanguageTranslation({
                  labelName: "type_art",
                  languageCode: langId || "fr",
                }) || "Type of art"}
              </Paragraph>
              <Paragraph text18 className="!font-normal !text-dark-grey">
                {LanguageTranslation({
                  labelName: "type_art_value",
                  languageCode: langId || "fr",
                }) || "danseur"}
              </Paragraph>
            </li>
            <li className="flex items-center justify-between gap-3 mb-3">
              <Paragraph text18 className="!font-normal">
                {LanguageTranslation({
                  labelName: "seller_name",
                  languageCode: langId || "fr",
                }) || "Seller Name"}
              </Paragraph>
              <Paragraph text18 className="!font-normal !text-dark-grey">
                {LanguageTranslation({
                  labelName: "seller_name_value",
                  languageCode: langId || "fr",
                }) || "Alen walker"}
              </Paragraph>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PurchaseDetail;
