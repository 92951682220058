import React, { useEffect, useRef, useState } from "react";
import PopularOfWeek from "./PopularOfWeek";
import ArtistOfWeek from "./ArtistOfWeek";
import Paragraph from "../../../components/core/typography/Paragraph";
import Profile from "../../../assets/images/dummy_profile.png";
import FeedImage from "../../../assets/images/feed-img.png";
import FeedImage2 from "../../../assets/images/feed-img-2.png";
import { FaHeart, FaThumbsUp } from "react-icons/fa";
import { IoMdChatboxes, IoMdShareAlt } from "react-icons/io";
import { HiMiniPlusCircle } from "react-icons/hi2";
import { TbRepeat } from "react-icons/tb";
import { IoEllipsisVertical } from "react-icons/io5";
import { LuZoomIn } from "react-icons/lu";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pathRoute } from "../../../routes/pathRoute";
import { BsFillPlusSquareFill } from "react-icons/bs";

function Social() {
  const [isOpen, setIsOpen] = useState(false);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    mobileFirst: true,
    slidesToScroll: 1,
  };

  const profileDropDownItems = [
    { Link: "", LinkName: "Report" },
    {
      Link: "",
      LinkName: "Block",
    },
  ];
  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };
  return (
    <div className="grid grid-cols-12">
      <div className="xxl:col-span-9 md:col-span-8 col-span-12 md:order-1 order-2 xl:py-6 py-4 xl:ps-10 lg:px-6 md:px-4 px-3 xl:pe-30 md:border-r md:border-medium-grey ">
        <div className="flex flex-col">
          <Link
            to={pathRoute.website.createPost}
            className="md:order-1 order-2 border border-medium-grey rounded-lg px-5 py-3.5 flex items-center gap-3 md:mb-30 md:mt-0 mt-5"
          >
            <BsFillPlusSquareFill size={30} color="#121111" />
            <Paragraph text20 className="font-medium">
              Create post
            </Paragraph>
          </Link>
          <PopularOfWeek />
          <ul className="mt-10 max-w-[555px] order-3">
            <li className="relative block pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 pt-[10px]">
              <div className="flex items-center absolute left-0 top-0 z-[1]">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                  <img src={Profile} alt="profile-photo" />
                </div>
                <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
                  <div>
                    <Paragraph text18 className="!leading-4">
                      @Aena Gilbert
                    </Paragraph>
                    <Paragraph
                      text14
                      className="!leading-[17px] !font-normal !text-dark-grey"
                    >
                      2 hours ago
                    </Paragraph>
                  </div>
                  <Dropdown
                    onClick={() => toggleDropdown("userProfile")}
                    dropdownItems={profileDropDownItems}
                    dropdownWidth="w-[174px]"
                  >
                    <IoEllipsisVertical size={20} />
                  </Dropdown>
                </div>
              </div>
              <div className="relative pb-6 max-w-[490px]">
                <div className="block rounded-[20px] overflow-hidden relative w-auto  ">
                  <img
                    src={FeedImage}
                    alt="feed-img"
                    className="w-full h-auto rounded-[20px] bg-light-grey cursor-pointer"
                  />
                </div>
                <div className="absolute right-0 top-0 inline-flex items-center justify-center bg-white w-[60px] rounded-3xl py-1.5 px-3.5 cursor-pointer">
                  <LuZoomIn size={22} color="#FFD45A" />
                </div>
                <div className="flex items-center justify-between gap-2.5 absolute bottom-0 w-full">
                  <button className="cursor-pointer bg-site-yellow flex items-center gap-1 rounded-3xl px-3 py-1.5">
                    <Paragraph text14 className="!font-medium">
                      Comment
                    </Paragraph>
                    <HiMiniPlusCircle size={18} />
                  </button>
                  <div className="flex items-center md:gap-5 gap-3">
                    <button
                      className={`cursor-pointer bg-site-yellow rounded-full w-[30px] h-[30px] flex items-center justify-center relative`}
                      disabled
                    >
                      <FaHeart size={16} />
                      <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        1
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdChatboxes
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                      <span className="bg-site-red cursor-default min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        2
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative rotate-90">
                      <TbRepeat
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdShareAlt
                        size={20}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <Paragraph
                text14
                className="!text-site-black !font-normal md:mt-5 mt-4"
              >
                Hey! I would like to share latest abstract piece.
              </Paragraph>
            </li>

            {/* Text Post */}
            <li className="relative block pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 pt-[10px]">
              <div className="flex items-center absolute left-0 top-0 z-[1]">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                  <img src={Profile} alt="profile-photo" />
                </div>
                <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
                  <div>
                    <Paragraph text18 className="!leading-4">
                      @Aena Gilbert
                    </Paragraph>
                    <Paragraph
                      text14
                      className="!leading-[17px] !font-normal !text-dark-grey"
                    >
                      2 hours ago
                    </Paragraph>
                  </div>
                  <Dropdown
                    onClick={() => toggleDropdown("userProfile")}
                    dropdownItems={profileDropDownItems}
                    dropdownWidth="w-[174px]"
                  >
                    <IoEllipsisVertical size={20} />
                  </Dropdown>
                </div>
              </div>
              <div className="relative pb-6 max-w-[490px]">
                <div className="flex items-center justify-center rounded-[20px] overflow-hidden relative w-auto px-10 py-20 md:min-h-[370px] min-h-[300px] bg-[#FCF4D0]  ">
                  <Paragraph className="lg:text-[50px] text-[36px] lg:leading-[56px] leading-[42px] font-medium">
                    Does anyone know good art school in London?
                  </Paragraph>
                </div>
                <div className="flex items-center justify-between gap-2.5 absolute bottom-0 w-full">
                  <button className="cursor-pointer bg-site-yellow flex items-center gap-1 rounded-3xl px-3 py-1.5">
                    <Paragraph text14 className="!font-medium">
                      Comment
                    </Paragraph>
                    <HiMiniPlusCircle size={18} />
                  </button>
                  <div className="flex items-center md:gap-5 gap-3">
                    <button
                      className={`cursor-pointer bg-site-yellow rounded-full w-[30px] h-[30px] flex items-center justify-center relative`}
                      disabled
                    >
                      <FaHeart size={16} />
                      <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        1
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdChatboxes
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                      <span className="bg-site-red cursor-default min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        2
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative rotate-90">
                      <TbRepeat
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdShareAlt
                        size={20}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </li>

            {/* With Price */}
            <li className="relative block pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 pt-[10px]">
              <div className="flex items-center absolute left-0 top-0 z-[1]">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                  <img src={Profile} alt="profile-photo" />
                </div>
                <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
                  <div>
                    <Paragraph text18 className="!leading-4">
                      @Aena Gilbert
                    </Paragraph>
                    <Paragraph
                      text14
                      className="!leading-[17px] !font-normal !text-dark-grey"
                    >
                      2 hours ago
                    </Paragraph>
                  </div>
                  <Dropdown
                    onClick={() => toggleDropdown("userProfile")}
                    dropdownItems={profileDropDownItems}
                    dropdownWidth="w-[174px]"
                  >
                    <IoEllipsisVertical size={20} />
                  </Dropdown>
                </div>
              </div>
              <div className="relative pb-6 max-w-[490px]">
                <div className="block rounded-[20px] overflow-hidden relative w-auto  ">
                  <img
                    src={FeedImage2}
                    alt="feed-img"
                    className="w-full h-auto rounded-[20px] bg-light-grey cursor-pointer"
                  />
                  <div className="absolute w-full left-0 bottom-0 flex justify-between items-center gap-[6px] px-6 py-4 bg-gradient-to-r from-white/5 -from-[4.18%] backdrop-blur-[30px] to-transparent to-[104.23%]">
                    <Paragraph text14 className="!text-white !font-normal">
                      $300
                    </Paragraph>
                    <Link
                      to={""}
                      className="text-sm leading-4 text-white font-medium inline-flex items-center"
                    >
                      View more
                      <MdKeyboardArrowRight size={20} color="#fff" />
                    </Link>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-2.5 absolute bottom-0 w-full">
                  <button className="cursor-pointer bg-site-yellow flex items-center gap-1 rounded-3xl px-3 py-1.5">
                    <Paragraph text14 className="!font-medium">
                      Comment
                    </Paragraph>
                    <HiMiniPlusCircle size={18} />
                  </button>
                  <div className="flex items-center md:gap-5 gap-3">
                    <button
                      className={`cursor-pointer bg-site-yellow rounded-full w-[30px] h-[30px] flex items-center justify-center relative`}
                      disabled
                    >
                      <FaHeart size={16} />
                      <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        1
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdChatboxes
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                      <span className="bg-site-red cursor-default min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        2
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative rotate-90">
                      <TbRepeat
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdShareAlt
                        size={20}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <Paragraph text14 className="!text-site-black !font-normal mt-5">
                This digital artwork, titled 'Ethereal Realms' transports
                viewers to a surreal world where the boundaries between reality
                and imagination blur. Crafted with meticulous attention Read
                More...
              </Paragraph>
            </li>

            {/* Carousel */}
            <li className="relative block pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 pt-[10px]">
              <div className="flex items-center absolute left-0 top-0 z-[1]">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                  <img src={Profile} alt="profile-photo" />
                </div>
                <div className="bg-white flex items-center md:gap-10 gap-4 ps-6 pe-4 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative sm:-left-[15px] -left-[20px] -z-[1]">
                  <div>
                    <Paragraph text18 className="!leading-4">
                      @Aena Gilbert
                    </Paragraph>
                    <Paragraph
                      text14
                      className="!leading-[17px] !font-normal !text-dark-grey"
                    >
                      2 hours ago
                    </Paragraph>
                  </div>
                  <Dropdown
                    onClick={() => toggleDropdown("userProfile")}
                    dropdownItems={profileDropDownItems}
                    dropdownWidth="w-[174px]"
                  >
                    <IoEllipsisVertical size={20} />
                  </Dropdown>
                </div>
              </div>
              <div className="relative feed-carousel pb-6 max-w-[490px]">
                <Slider {...settings}>
                  <div>
                    <div className="block rounded-[20px] overflow-hidden relative w-auto">
                      <img
                        src={FeedImage2}
                        alt="feed-img"
                        className="w-full sm:h-auto h-[180px] object-cover rounded-[20px] bg-light-grey cursor-pointer"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="block rounded-[20px] overflow-hidden relative w-auto  ">
                      <img
                        src={FeedImage2}
                        alt="feed-img"
                        className="w-full sm:h-auto h-[180px] object-cover rounded-[20px] bg-light-grey cursor-pointer"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="block rounded-[20px] overflow-hidden relative w-auto  ">
                      <img
                        src={FeedImage2}
                        alt="feed-img"
                        className="w-full sm:h-auto h-[180px] object-cover rounded-[20px] bg-light-grey cursor-pointer"
                      />
                    </div>
                  </div>
                </Slider>

                <div className="flex items-center justify-between gap-2.5 absolute bottom-0 w-full">
                  <button className="cursor-pointer bg-site-yellow flex items-center gap-1 rounded-3xl px-3 py-1.5">
                    <Paragraph text14 className="!font-medium">
                      Comment
                    </Paragraph>
                    <HiMiniPlusCircle size={18} />
                  </button>
                  <div className="flex items-center md:gap-5 gap-3">
                    <button
                      className={`cursor-pointer bg-site-yellow rounded-full w-[30px] h-[30px] flex items-center justify-center relative`}
                      disabled
                    >
                      <FaHeart size={16} />
                      <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        1
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdChatboxes
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                      <span className="bg-site-red cursor-default min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[8px] -right-[8px]">
                        2
                      </span>
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative rotate-90">
                      <TbRepeat
                        size={18}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                    <button className="bg-site-yellow rounded-full  w-[30px] h-[30px] flex items-center justify-center relative">
                      <IoMdShareAlt
                        size={20}
                        className="cursor-pointer"
                        // onClick={() => handleCommentSection(item)}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="xxl:col-span-3 md:col-span-4 col-span-12 md:order-2 order-1">
        <div className="sticky top-0">
          <ArtistOfWeek />
        </div>
      </div>
    </div>
  );
}

export default Social;
