import React from "react";
import EmptyTransactions from "../../../../assets/images/empty_transactions.svg";
import Button from "../../../../components/core/form-components/Button";
import Paragraph from "../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";

function MyTransactions() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  return (
    <div className="">
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "my_transactions",
          languageCode: langId || "fr",
        }) || "My Transactions"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        {/* <div className="flex items-center justify-center flex-col max-w-[336px] mx-auto text-center">
          <img
            src={EmptyTransactions}
            alt="empty-purchase"
            title="purchase"
            width={314}
            height={119}
            className="mb-6"
          />
          <Paragraph text20 className="!leading-6 mb-3 !font-medium">
            It looks like there you haven't been did any transaction yet.
          </Paragraph>
          <Paragraph text18 className="!text-dark-grey !font-normal mb-4">
            Don't worry, you can start making more sales through the app today!
          </Paragraph>
          <Button primary>Explore Now</Button>
        </div> */}
        <ul className="max-h-[calc(100vh-224px)] overflow-y-auto">
          <li className="border border-medium-grey rounded-xl lg:p-6 p-4 flex items-center justify-between gap-4 mb-3">
            <div className="flex items-start gap-4">
              <FaCircleCheck size={30} color="#1AB53C" />
              <div>
                <Paragraph text16 className="!font-normal  !text-dark-grey">
                  #5 | 22 Oct, 2024
                </Paragraph>
                <Paragraph text16 className="!font-normal  ">
                  Achat d’une video unique........
                </Paragraph>
                <Paragraph text16 className="!font-normal !text-dark-grey">
                  Donnez vie a vos dessins
                </Paragraph>
              </div>
            </div>
            <Paragraph text20 className="!font-medium">
              180€
            </Paragraph>
          </li>
          <li className="border border-medium-grey rounded-xl lg:p-6 p-4 flex items-center justify-between gap-4 mb-3">
            <div className="flex items-start gap-4">
              <FaCircleCheck size={30} color="#1AB53C" />
              <div>
                <Paragraph text16 className="!font-normal  !text-dark-grey">
                  #5 | 22 Oct, 2024
                </Paragraph>
                <Paragraph text16 className="!font-normal  ">
                  Achat d’une video unique........
                </Paragraph>
                <Paragraph text16 className="!font-normal !text-dark-grey">
                  Donnez vie a vos dessins
                </Paragraph>
              </div>
            </div>
            <Paragraph text20 className="!font-medium">
              180€
            </Paragraph>
          </li>
          <li className="border border-medium-grey rounded-xl lg:p-6 p-4 flex items-center justify-between gap-4 mb-3">
            <div className="flex items-start gap-4">
              <FaCircleCheck size={30} color="#1AB53C" />
              <div>
                <Paragraph text16 className="!font-normal  !text-dark-grey">
                  #5 | 22 Oct, 2024
                </Paragraph>
                <Paragraph text16 className="!font-normal  ">
                  Achat d’une video unique........
                </Paragraph>
                <Paragraph text16 className="!font-normal !text-dark-grey">
                  Donnez vie a vos dessins
                </Paragraph>
              </div>
            </div>
            <Paragraph text20 className="!font-medium">
              180€
            </Paragraph>
          </li>
          <li className="border border-medium-grey rounded-xl lg:p-6 p-4 flex items-center justify-between gap-4 mb-3">
            <div className="flex items-start gap-4">
              <FaCircleCheck size={30} color="#1AB53C" />
              <div>
                <Paragraph text16 className="!font-normal  !text-dark-grey">
                  #5 | 22 Oct, 2024
                </Paragraph>
                <Paragraph text16 className="!font-normal  ">
                  Achat d’une video unique........
                </Paragraph>
                <Paragraph text16 className="!font-normal !text-dark-grey">
                  Donnez vie a vos dessins
                </Paragraph>
              </div>
            </div>
            <Paragraph text20 className="!font-medium">
              180€
            </Paragraph>
          </li>
          <li className="border border-medium-grey rounded-xl lg:p-6 p-4 flex items-center justify-between gap-4 mb-3">
            <div className="flex items-start gap-4">
              <FaCircleCheck size={30} color="#1AB53C" />
              <div>
                <Paragraph text16 className="!font-normal  !text-dark-grey">
                  #5 | 22 Oct, 2024
                </Paragraph>
                <Paragraph text16 className="!font-normal  ">
                  Achat d’une video unique........
                </Paragraph>
                <Paragraph text16 className="!font-normal !text-dark-grey">
                  Donnez vie a vos dessins
                </Paragraph>
              </div>
            </div>
            <Paragraph text20 className="!font-medium">
              180€
            </Paragraph>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MyTransactions;
