import React from "react";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../../../components/core/form-components/SwitchToggle";

function NotificationPref() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  return (
    <div className="lg:p-30 md:p-4 p-3 !pt-0 grid grid-cols-12">
      <div className="xl:col-span-8 col-span-12">
        <ul>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {LanguageTranslation({
                labelName: "post_comment_label",
                languageCode: langId || "fr",
              }) || "When someone posts a comment on one of your artworks"}
            </Paragraph>
            <SwitchToggle />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {LanguageTranslation({
                labelName: "like_artwork_label",
                languageCode: langId || "fr",
              }) || "When someone likes on one of your artworks"}
            </Paragraph>
            <SwitchToggle />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {LanguageTranslation({
                labelName: "sold_artwork_label",
                languageCode: langId || "fr",
              }) || "When one of your works is sold"}
            </Paragraph>
            <SwitchToggle />
          </li>
          <li className="flex items-center justify-between gap-4 border border-medium-grey rounded-xl lg:px-6 md:px-4 px-3 md:py-4 py-3 mb-3">
            <Paragraph text18 className="!font-normal">
              {LanguageTranslation({
                labelName: "following_post_label",
                languageCode: langId || "fr",
              }) || "When an artist you are following posts"}
            </Paragraph>
            <SwitchToggle />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NotificationPref;
