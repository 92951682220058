import React from "react";
import SlidingTabBar from "../../../../../components/core/SlidingTabBar";
import ContactUs from "./ContactUs";
import { Tab } from "../../../../../interface/Tabs";
import NotificationPref from "./NotificationPref";
import ChangePassword from "./ChangePassword";

function Settings() {
  const tabs: Tab[] = [
    {
      id: "notification_preferences",
      name: "Notification Preferences",
      content: <NotificationPref />,
    },
    {
      id: "change_password",
      name: "Change Password",
      content: <ChangePassword />,
    },
    {
      id: "contact_us",
      name: "Contact Us",
      content: <ContactUs />,
    },
  ];
  return (
    <div className="border border-medium-grey rounded-2xl overflow-hidden">
      <SlidingTabBar tabs={tabs} borderedTab />
    </div>
  );
}

export default Settings;
