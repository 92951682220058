import React, { useState } from "react";
import { Form, Formik } from "formik";
import FormLabel from "../../../components/core/typography/FormLabel";
import TextArea from "../../../components/core/form-components/TextArea";
import RadioButton from "../../../components/core/form-components/RadioButton";
import UploadFile from "../../../assets/images/icon_image_upload.svg";
import Button from "../../../components/core/form-components/Button";
import Paragraph from "../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import InputType from "../../../components/core/form-components/InputType";
import SelectType from "../../../components/core/form-components/SelectType";
import Modal from "../../../components/core/Modal";
import UploadFileModal from "./UploadFileModal";
import GuideModal from "./GuideModal";
import ColorWheel from "../../../assets/images/icon_color_wheel.svg";
import ColorPickerModal from "./ColorPickerModal";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import DummyImg from "../../../assets/images/art2.png";
import { IoClose } from "react-icons/io5";

interface FormValues {
  otp: string;
}
function CreatePost() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const [modalOpen, setModalOpen] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const [colorPickerModal, setColorPickerModal] = useState(false);
  const options = [
    {
      label: "Option 1",
      value: "option-1",
    },
    {
      label: "Option 2",
      value: "option-2",
    },
    {
      label: "Option 3",
      value: "option-3",
    },
  ];
  const onSubmit = (values: FormValues) => {
    console.log("Form Data: ", values);
  };
  const handleClose = () => {
    setModalOpen(!modalOpen);
  };
  const handleGuideClose = () => {
    setGuideModal(!guideModal);
  };
  const handleColorPickerClose = () => {
    setColorPickerModal(!colorPickerModal);
  };
  return (
    <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3 ">
      <Paragraph text24 className="md:mb-6 sm:mb-5 mb-4">
        {LanguageTranslation({
          labelName: "create_post_new",
          languageCode: langId || "fr",
        }) || "Create New Post"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl overflow-hidden">
        <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="grid grid-cols-12">
                <div className="xl:col-span-3 sm:col-span-4 col-span-12 bg-light-grey xl:p-30 md:p-5 p-3">
                  <FormLabel>Upload image</FormLabel>
                  <label
                    htmlFor="upload-file"
                    className="px-4 py-3.5 md:h-[180px] h-[120px] bg-white rounded-lg border border-dashed border-medium-grey flex flex-col justify-center items-center gap-3 cursor-pointer"
                  >
                    <input
                      type="file"
                      name="upload-file"
                      id="upload-file"
                      multiple
                      className="sr-only"
                    />
                    <img
                      src={UploadFile}
                      alt="upload-file"
                      width={30}
                      height={30}
                    />
                    <span className="text-sm leading-3 text-dark-grey">
                      Upload file
                    </span>
                  </label>
                  <div className="flex flex-wrap items-stretch gap-3 mt-4">
                    <div className="relative sm:w-[calc(50%-12px)] w-[calc(25%-12px)]">
                      <img
                        src={DummyImg}
                        alt=""
                        className="object-cover rounded-[4px] aspect-[4/3]"
                      />
                      <span className="absolute -top-1.5 -right-1.5 sm:w-6 sm:h-6 w-5 h-5 flex items-center justify-center rounded-full bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md text-white cursor-pointer">
                        <IoClose />
                      </span>
                    </div>
                    <div className="relative sm:w-[calc(50%-12px)] w-[calc(25%-12px)]">
                      <img
                        src={DummyImg}
                        alt=""
                        className="object-cover rounded-[4px] aspect-[4/3]"
                      />
                      <span className="absolute -top-1.5 -right-1.5 sm:w-6 sm:h-6 w-5 h-5 flex items-center justify-center rounded-full bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md text-white cursor-pointer">
                        <IoClose />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-9 sm:col-span-8 col-span-12 xl:p-30 md:p-5 p-3">
                  <div className="grid grid-cols-12 lg:gap-6 md:gap-5 gap-3">
                    <div className="col-span-12">
                      <FormLabel>Description</FormLabel>
                      <TextArea
                        name="description"
                        rows={5}
                        placeholder="What do you want to share?"
                      />
                    </div>
                    <div className="col-span-12">
                      <Paragraph text16 className="mb-3">
                        Would you like to sell your product?
                      </Paragraph>
                      <div className="flex items-center gap-[30px]">
                        <RadioButton
                          id="yes"
                          name="sell-product"
                          labelClass="!text-base"
                        >
                          Yes
                        </RadioButton>
                        <RadioButton
                          id="no"
                          name="sell-product"
                          labelClass="!text-base"
                        >
                          No
                        </RadioButton>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center gap-[30px]">
                        <Paragraph text16>Framed artwork</Paragraph>
                        <SwitchToggle />
                      </div>
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>
                        Title<span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        required
                        name="title"
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>
                        Height<span className="text-site-red">*</span>{" "}
                        <span className="text-[#a0a0a0]">(cm)</span>
                      </FormLabel>
                      <InputType
                        name="height"
                        type="number"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>
                        Width<span className="text-site-red">*</span>{" "}
                        <span className="text-[#a0a0a0]">(cm)</span>
                      </FormLabel>
                      <InputType
                        name="width"
                        type="number"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>
                        Weight<span className="text-site-red">*</span>{" "}
                        <span className="text-[#a0a0a0]">(kg)</span>
                      </FormLabel>
                      <InputType
                        name="weight"
                        type="number"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>
                        Depth<span className="text-site-red">*</span>{" "}
                        <span className="text-[#a0a0a0]">(kg)</span>
                      </FormLabel>
                      <InputType
                        name="depth"
                        type="number"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>
                        Price<span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        required
                        name="price"
                        type="number"
                        placeholder="Enter price"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6 sm:col-span-6 col-span-12">
                      <FormLabel>
                        Exemplar<span className="text-site-red">*</span>
                      </FormLabel>
                      <InputType
                        name="price"
                        type="number"
                        placeholder="Enter quantity"
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6 sm:col-span-6 col-span-12">
                      <FormLabel>
                        Category<span className="text-site-red">*</span>
                      </FormLabel>
                      <SelectType
                        onChange={onSubmit}
                        options={options}
                        placeholder="Select category"
                        fullWidth
                      />
                    </div>
                    <div className="xl:col-span-4 lg:col-span-6 sm:col-span-6 col-span-12">
                      <FormLabel>Artag</FormLabel>
                      <SelectType
                        onChange={onSubmit}
                        options={options}
                        placeholder="Select artag"
                        fullWidth
                        isSearchable
                      />
                    </div>
                    <div className="sm:col-span-6 col-span-12">
                      <FormLabel>Colourimetry</FormLabel>
                      <span
                        onClick={() => setColorPickerModal(true)}
                        className="flex items-center justify-center rounded-full bg-site-black w-10 h-10"
                      >
                        <img
                          src={ColorWheel}
                          alt="color-wheel"
                          width={26}
                          height={26}
                        />
                      </span>
                    </div>
                    <div className="col-span-12 mt-1.5">
                      <div className="flex items-center xl:justify-end justify-start gap-4">
                        <Button borderButton className="lg:w-auto w-full">
                          Cancel
                        </Button>
                        <Button disabled className="lg:w-auto w-full">
                          Post
                        </Button>
                      </div>
                      {/* <Button
                    onClick={() => setGuideModal(true)}
                    primary
                    className="min-w-[240px] sm:w-auto w-full"
                  >
                    Save
                  </Button> */}
                      <Modal
                        open={guideModal}
                        onClose={handleGuideClose}
                        header
                        width="w-[650px] lg:h-[450px] sm:h-[380px] h-auto"
                      >
                        <GuideModal
                          open={guideModal}
                          onClose={handleGuideClose}
                        />
                      </Modal>
                      <Modal
                        open={colorPickerModal}
                        onClose={handleColorPickerClose}
                        header
                      >
                        <ColorPickerModal
                          open={colorPickerModal}
                          onClose={handleColorPickerClose}
                        />
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreatePost;
