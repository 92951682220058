import React from "react";
import { SwitchToggleProps } from "../../../interface/SwitchToggle";

function SwitchToggle({
  id,
  children,
  leftLabel,
  rightLabel,
  onChange,
  isChecked,
  disabled,
  withInnerText,
  ...props
}: SwitchToggleProps) {
  const handleChange = async () => {
    if (onChange) {
      await onChange(!isChecked);
    }
  };
  return (
    <div className="flex items-center gap-3">
      {leftLabel && (
        <span className=" text-base leading-6 font-semibold text-site-black">
          {leftLabel}
        </span>
      )}
      <label className="inline-flex items-center cursor-pointer" htmlFor={id}>
        <input
          type="checkbox"
          className="sr-only peer"
          onChange={handleChange}
          checked={isChecked}
          disabled={disabled}
        />
        <div
          className={`relative   rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:bg-white after:rounded-full 
       after:transition-all dark:border-gray-600 peer-checked:bg-site-yellow ${
         withInnerText
           ? "w-[94px] h-[26px] before:text-sm before:font-medium before:content-['Follow'] peer-checked:before:content-['Following'] before:block before:absolute before:top-[4px] before:right-[30px] peer-checked:before:right-auto peer-checked:before:left-[10px] peer-checked:after:start-[56px] after:h-[16px] after:w-[16px] after:top-[5px] after:start-[6px] before:transition-all bg-site-yellow"
           : "w-[42px] h-[22px] peer-checked:after:start-[10px] after:h-[14px] after:w-[14px] after:top-[4px] after:start-[4px] bg-medium-grey"
       }`}
        ></div>
      </label>
      {rightLabel && (
        <span className=" text-base leading-6 font-normal text-dark-grey">
          {rightLabel}
        </span>
      )}
    </div>
  );
}

export default SwitchToggle;
