import React from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import EmptySales from "../../../../assets/images/empty_sales.svg";
import Button from "../../../../components/core/form-components/Button";
import Art1 from "../../../../assets/images/art1.png";
import Art2 from "../../../../assets/images/art2.png";
import Art3 from "../../../../assets/images/art3.png";
import Art4 from "../../../../assets/images/art4.png";
import Art5 from "../../../../assets/images/art5.png";
import { Link } from "react-router-dom";
import { pathRoute } from "../../../../routes/pathRoute";

function MySales() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "my_sales",
          languageCode: langId || "fr",
        }) || "My Sales"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        {/* <div className="flex items-center justify-center flex-col max-w-[336px] mx-auto text-center">
          <img
            src={EmptySales}
            alt="empty-purchase"
            title="purchase"
            width={248}
            height={206}
            className="mb-6"
          />
          <Paragraph text20 className="!leading-6 mb-3 !font-medium">
            It looks like there haven't been any sales recorded yet.
          </Paragraph>
          <Paragraph text18 className="!text-dark-grey !font-normal mb-4">
            Don't worry, you can start making more sales through the app today!
          </Paragraph>
          <Button primary>Explore Now</Button>
        </div> */}
        <div className="grid xxl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
          <Link to={pathRoute.website.saleDetail} className="block col-span-1">
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img src={Art1} alt="" className="aspect-square" />
            </div>
            <Paragraph text18 className="">
              {LanguageTranslation({
                labelName: "product_detail",
                languageCode: langId || "fr",
              }) || "Bonsai"}
            </Paragraph>
            <Paragraph text18 className="!font-normal">
              {LanguageTranslation({
                labelName: "product_price",
                languageCode: langId || "fr",
              }) || "180€"}
            </Paragraph>
          </Link>
          <Link to={pathRoute.website.saleDetail} className="block col-span-1">
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img src={Art2} alt="" className="aspect-square object-cover" />
            </div>
            <Paragraph text18 className="">
              Bonsai
            </Paragraph>
            <Paragraph text18 className="!font-normal">
              180€
            </Paragraph>
          </Link>
          <Link to={pathRoute.website.saleDetail} className="block col-span-1">
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img src={Art3} alt="" className="aspect-square object-cover" />
            </div>
            <Paragraph text18 className="">
              Bonsai
            </Paragraph>
            <Paragraph text18 className="!font-normal">
              180€
            </Paragraph>
          </Link>
          <Link to={pathRoute.website.saleDetail} className="block col-span-1">
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img src={Art4} alt="" className="aspect-square object-cover" />
            </div>
            <Paragraph text18 className="">
              Bonsai
            </Paragraph>
            <Paragraph text18 className="!font-normal">
              180€
            </Paragraph>
          </Link>
          <Link to={pathRoute.website.saleDetail} className="block col-span-1">
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img src={Art5} alt="" className="aspect-square object-cover" />
            </div>
            <Paragraph text18 className="">
              Bonsai
            </Paragraph>
            <Paragraph text18 className="!font-normal">
              180€
            </Paragraph>
          </Link>
          <Link to={pathRoute.website.saleDetail} className="block col-span-1">
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img src={Art5} alt="" className="aspect-square object-cover" />
            </div>
            <Paragraph text18 className="">
              Bonsai
            </Paragraph>
            <Paragraph text18 className="!font-normal">
              180€
            </Paragraph>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MySales;
