import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { getFeedDetailsById } from "../../../services/profileService";
import { ArtDetails } from "../../../interface/ArtDetails";
import DummyProfile from "../../../assets/images/dummy_logo.png";
import Paragraph from "../../../components/core/typography/Paragraph";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FaPlayCircle } from "react-icons/fa";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";

interface FeedInterface {
  id: string;
}
function FeedSingleDetails({ id }: FeedInterface) {
  const dispatch = useDispatch();
  const [feedDetails, setFeedDetails] = useState<ArtDetails | undefined>(
    undefined
  );
  const [commentCount, setCommentCount] = useState<number>(0);
  const { t } = useTranslation();
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const fetchFeedDetails = async (id: number) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getFeedDetailsById(id);
        if (response?.data?.code === 200) {
          setFeedDetails(response?.data?.publication);
          setCommentCount(response?.data?.countComments);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchFeedDetails(parseInt(id));
    }
  }, [id]);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const timeDifference = (date: string) => {
    const today = moment();
    const createdDate = moment(date);
    let diff = today.diff(createdDate, "years");
    let message = t("profile.years_ago");
    if (diff === 0) {
      diff = today.diff(createdDate, "months");
      message = t("profile.months_ago");
      if (diff === 0) {
        diff = today.diff(createdDate, "weeks");
        message = t("profile.weeks_ago");
        if (diff === 0) {
          diff = today.diff(createdDate, "days");
          message = t("profile.days_ago");
          if (diff === 0) {
            diff = today.diff(createdDate, "hours");
            message = t("profile.hours_ago");
            if (diff === 0) {
              diff = today.diff(createdDate, "minutes");
              message = t("profile.minutes_ago");
            }
          }
        }
      }
    }
    if (diff === 0) {
      return t("profile.just_now");
    }
    return `${diff} ${message}`;
  };

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  return (
    <>
      <div
        key={feedDetails?.id}
        className="relative block pb-[30px] md:ps-[66px] ps-[40px] pt-[10px]"
      >
        <div className="flex items-center absolute left-0 top-0 z-[1]">
          <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
            <img
              src={getProfileImage(feedDetails?.user?.avatar ?? "")}
              alt="profile-photo"
            />
          </div>
          <div className="bg-white flex items-center gap-4 px-5 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative -left-[15px] -z-[1]">
            <Paragraph text22>@{feedDetails?.user?.nickname}</Paragraph>
            <Paragraph
              text18
              className="!leading-[17px] font-normal !text-dark-grey"
            >
              {timeDifference(feedDetails?.createdAt ?? "")}
            </Paragraph>
          </div>
        </div>
        {(feedDetails?.video || feedDetails?.images) && (
          <>
            <div className="relative pb-6">
              <div className="block rounded-[20px] overflow-hidden relative w-auto max-h-[390px] ">
                {feedDetails?.video ? (
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                    }}
                  >
                    <img
                      id={`image-${feedDetails.id}`}
                      src={feedDetails?.video?.imageUrl}
                      className={
                        "max-h-[390px] h-full rounded-[20px] bg-light-grey"
                      }
                    />
                    <span className="absolute inset-0 flex items-center justify-center">
                      <FaPlayCircle
                        size={40}
                        className="text-base"
                        onClick={() =>
                          openLightbox(feedDetails?.video?.videoUrl)
                        }
                      />
                    </span>
                  </div>
                ) : (
                  <img
                    src={
                      feedDetails?.images && feedDetails?.images[0]?.imageUrl
                    }
                    alt="feed-img"
                    className="max-h-[390px] h-full rounded-[20px] bg-light-grey cursor-pointer"
                  />
                )}
              </div>
              {/* <div className="flex items-center gap-5 absolute bottom-0">
                <button className="bg-site-green cursor-default rounded-full w-[30px] h-[30px] flex items-center justify-center relative">
                  <FaThumbsUp
                    size={14}
                    color="#fff"
                    className="cursor-default"
                  />
                  {feedDetails?.likes && feedDetails?.likes?.length > 0 && (
                    <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]">
                      {feedDetails?.likes?.length}
                    </span>
                  )}
                </button>
                <button className="bg-site-yellow rounded-full cursor-default w-[30px] h-[30px] flex items-center justify-center relative">
                  <IoMdChatboxes
                    size={18}
                    color="#fff"
                    className="cursor-default"
                  />
                  {commentCount > 0 && (
                    <span
                      className="bg-site-red min-w-[18px] text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]"
                      // onClick={() => handleCommentSection(item)}
                    >
                      {commentCount}
                    </span>
                  )}
                </button>
              </div> */}
            </div>
          </>
        )}

        {feedDetails?.video || feedDetails?.images ? (
          <Paragraph text18 className="!font-normal mt-4">
            <span>{feedDetails?.text?.contentText}</span>
          </Paragraph>
        ) : (
          <>
            <Paragraph text18 className="!font-normal mt-[50px]">
              <span>{feedDetails?.text?.contentText}</span>
            </Paragraph>
          </>
        )}
      </div>
      {/* End Post Data */}
      {lightboxOpen && (
        <div>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
}

export default FeedSingleDetails;
