import React from "react";
import IconSuccess from "../../../assets/images/icon_check_mark.svg";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokenSelector } from "../../../redux/slices/userSlice";
import { pathRoute } from "../../../routes/pathRoute";

function PaymentSuccess() {
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);

  const handleMore = () => {
    const urlWithToken = `${pathRoute.web.profile}?token=${token}`;
    navigate(urlWithToken);
  };
  return (
    <div className="text-center">
      {/* <span className="absolute right-5 top-5 cursor-pointer" onClick={onClose}>
        <IoCloseOutline size="24" color="#333333" />
      </span> */}
      <div className="md:mb-30 mb-5 text-center inline-block xl:max-w-[126px] md:max-w-[100px] max-w-[80px]">
        <img src={IconSuccess} alt="icon-success" />
      </div>
      <Paragraph text24 className="mb-4">
        Payment Successful!!!
      </Paragraph>
      <Paragraph text20 className="mb-30 font-normal !leading-[30px]">
        Thank you for your purchase. You will be redirected to profile page
        shortly.
      </Paragraph>
      <Button primary onClick={handleMore}>
        Explore More
      </Button>
    </div>
  );
}

export default PaymentSuccess;
