import React from "react";
import NoDataFound from "../../../assets/images/empty_event.svg";
import EventImg from "../../../assets/images/event_img.png";
import Paragraph from "../../../components/core/typography/Paragraph";
import { Link } from "react-router-dom";
import { ImLocation } from "react-icons/im";

function Event() {
  return (
    <div className="px-4">
      <div className="grid sm:grid-cols-3 grid-cols-2  gap-4">
        <div className="col-span-1">
          <Link to={""}>
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img
                src={EventImg}
                alt="event-img"
                width={184}
                height={184}
                className="w-full object-cover"
              />
            </div>
            <Paragraph text18 className="mb-1 !leading-4">
              Royal College of Art
            </Paragraph>
            <Paragraph
              text14
              className="mb-2 !text-[#737373] !leading-4 !font-normal"
            >
              Nov 26 . 12:00pm
            </Paragraph>
            <Paragraph text14 className="!font-normal flex items-center gap-1">
              <ImLocation />
              France
            </Paragraph>
          </Link>
        </div>
        <div className="col-span-1">
          <Link to={""}>
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img
                src={EventImg}
                alt="event-img"
                width={184}
                height={184}
                className="w-full object-cover"
              />
            </div>
            <Paragraph text18 className="mb-1 !leading-4">
              Royal College of Art
            </Paragraph>
            <Paragraph
              text14
              className="mb-2 !text-[#737373] !leading-4 !font-normal"
            >
              Nov 26 . 12:00pm
            </Paragraph>
            <Paragraph text14 className="!font-normal flex items-center gap-1">
              <ImLocation />
              France
            </Paragraph>
          </Link>
        </div>
        <div className="col-span-1">
          <Link to={""}>
            <div className="rounded-[20px] overflow-hidden mb-3">
              <img
                src={EventImg}
                alt="event-img"
                width={184}
                height={184}
                className="w-full object-cover"
              />
            </div>
            <Paragraph text18 className="mb-1 !leading-4">
              Royal College of Art
            </Paragraph>
            <Paragraph
              text14
              className="mb-2 !text-[#737373] !leading-4 !font-normal"
            >
              Nov 26 . 12:00pm
            </Paragraph>
            <Paragraph text14 className="!font-normal flex items-center gap-1">
              <ImLocation />
              France
            </Paragraph>
          </Link>
        </div>
      </div>
      <div className="break-inside-avoid text-center">
        <img
          src={NoDataFound}
          alt="no-data-found"
          className="mb-6 inline-block"
          width={"250px"}
          height={"142px"}
        />
        <Paragraph text18 className="!font-medium">
          No events
        </Paragraph>
      </div>
    </div>
  );
}

export default Event;
