import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../web-component/Sidebar";
import Header from "../web-component/Header";
import { Outlet, useLocation } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import MyAccount from "../../pages/website/my-account/MyAccount";
import AccountSidebar from "../../pages/website/my-account/AccountSidebar";

function WebWrapper() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [sideBarMobileOpen, setSideBarMobileOpen] = useState(false);
  const [shadow, setShadow] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleSidebar = () => {
    setSideBarOpen(!sideBarOpen);
  };
  const location = useLocation();

  console.log("fdsfdsfs", location);
  const handleMobileSidebar = () => {
    setSideBarMobileOpen(!sideBarMobileOpen);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (scrollRef.current.scrollTop > 0) {
  //       setShadow(true);
  //     } else {
  //       setShadow(false);
  //     }
  //   };

  //   const currentDiv = scrollRef.current as HTMLDivElement;
  //   currentDiv.addEventListener("scroll", handleScroll);

  //   return () => {
  //     currentDiv.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (sideBarMobileOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [sideBarMobileOpen]);
  return (
    <main className="flex items-stretch h-screen">
      <div
        className={`sidebar-wrapper ${
          sideBarOpen ? "lg:w-[80px]" : "lg:w-[254px]"
        }   lg:p-4 border-r border-r-medium-grey relative transition-[width] duration-500 ease-in-out`}
      >
        {sideBarMobileOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 lg:z-30 z-[15] block lg:hidden sidebar"
            onClick={handleMobileSidebar}
          ></div>
        )}
        <Sidebar
          sideBarOpen={sideBarOpen}
          sideBarMobileOpen={sideBarMobileOpen}
          handleMobileSidebar={handleMobileSidebar}
        />
        <button
          className="absolute z-40 bg-site-yellow text-site-black rounded-full w-6 h-6 items-center justify-center border border-site-yellow  -right-[13px] top-[24px] lg:flex hidden"
          onClick={handleSidebar}
        >
          {sideBarOpen ? (
            <MdKeyboardArrowRight fontSize={"18px"} />
          ) : (
            <MdKeyboardArrowLeft fontSize={"18px"} />
          )}
        </button>
      </div>
      <div
        className={`transition-[width] duration-500 ease-in-out ${
          sideBarOpen
            ? "lg:w-[calc(100%-80px)]"
            : "lg:w-[calc(100%-254px)] w-full"
        }  `}
        ref={scrollRef}
      >
        <Header
          sideBarMobileOpen={sideBarMobileOpen}
          handleMobileSidebar={handleMobileSidebar}
        />
        <div className="h-[calc(100vh-67px)] overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default WebWrapper;
