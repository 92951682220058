import React from "react";
import { Outlet } from "react-router-dom";
import AccountSidebar from "./AccountSidebar";

function MyAccount() {
  return (
    <div className="flex items-stretch h-full">
      <div className="w-[254px] py-30 border-r border-r-medium-grey h-full lg:block hidden">
        <AccountSidebar />
      </div>
      <div className="lg:w-[calc(100%-254px)] w-full xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3 h-[calc(100vh-67px)] overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
}

export default MyAccount;
