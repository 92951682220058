import React, { useState } from "react";
import Paragraph from "./typography/Paragraph";
import Button from "./form-components/Button";
import { useFormikContext } from "formik"; // Import Formik types
import {
  CheckOtherServices,
  CheckShippingAddress,
} from "../../services/checkoutService";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  servicesSelector,
  setServices,
} from "../../redux/slices/otherServices";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { setPaymentInfo } from "../../redux/slices/paymentInfo";
import {
  isConfirmService,
  isConfirmServiceSelector,
  notConfirmService,
} from "../../redux/slices/isConfirmServicesSlice";
import { useTranslation } from "react-i18next";

interface Step {
  name: string;
  content: React.ReactNode;
}

interface StepperProps {
  steps: Step[];
  orientation?: "horizontal" | "vertical";
  horizontalPosition?: string;
  verticalPosition?: string;
  showButton: boolean;
  setShowButton: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isSubmitting: any;
  handleBack: any;
}

const Stepper = ({
  steps,
  orientation = "horizontal",
  horizontalPosition,
  verticalPosition,
  showButton,
  setShowButton,
  setCurrentStep,
  activeStep,
  isSubmitting,
  handleBack,
}: StepperProps) => {
  const dispatch = useDispatch();
  const services = useSelector(servicesSelector);
  const isConfirm = useSelector(isConfirmServiceSelector);
  const { t } = useTranslation();

  const isHorizontal = orientation === "horizontal";
  const { values, setFieldValue } = useFormikContext<{
    name: string;
    email: string;
    phone_number: string;
    phone_country_code: string;
    shipping_address: string;
    shipping_city: string;
    shipping_zip: string;
    shiiping_country: string;
    billing_address: string;
    billing_city: string;
    billing_zip: string;
    blilling_country: string;
    isBillingSame: boolean;
    id: number;
    cardHolderName: string;
    insurance: boolean;
  }>();

  return (
    <>
      <div
        className={`flex w-full ${isHorizontal ? "flex-row" : "flex-col"} ${
          horizontalPosition ? horizontalPosition : ""
        } items-center sm:gap-4 gap-2`}
      >
        {steps?.map((step, index) => (
          <div
            key={index}
            className="flex items-center sm:gap-3 gap-1 sm:w-1/4 relative z-0"
          >
            <div className="flex items-start sm:gap-3 gap-1 flex-col">
              <div
                className={`flex items-center justify-center w-[26px] h-[26px] rounded-full font-sm leading-[17px] 
                  ${
                    activeStep === index
                      ? "bg-site-yellow text-site-black"
                      : activeStep > index
                      ? "bg-site-yellow text-site-black" // Completed steps
                      : "bg-light-grey text-medium-grey"
                  }`}
              >
                {index + 1}
              </div>
              <Paragraph
                className={`${
                  activeStep === index
                    ? "text-site-black"
                    : activeStep > index
                    ? "text-site-black"
                    : "text-site-grey"
                } font-medium md:text-left text-center sm:text-base sm:leading-6 text-xs leading-[18px]`}
              >
                {step?.name}
              </Paragraph>
            </div>
            {index !== steps.length - 1 && (
              <div
                className={`border absolute top-[10px] left-[26px] -z-[1]  ${
                  isHorizontal
                    ? "border-t-[3px] w-full"
                    : "border-l-[3px] md:h-20 h-10"
                } 
                ${
                  activeStep > index
                    ? "border-site-yellow"
                    : "border-light-grey"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>

      {steps[activeStep].content}
    </>
  );
};

export default Stepper;
