import React, { useState } from "react";

interface Step {
  name: string;
  content: React.ReactNode;
}
interface StepperProgressBarProps {
  steps: Step[];
  orientation?: "horizontal" | "vertical";
  horizontalPosition?: string;
  verticalPosition?: string;
  showButton?: boolean;
  setShowButton?: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  isSubmitting?: any;
  handleBack?: any;
  activeStep: number;
}

function StepperWithProgressbar({
  steps,
  orientation = "horizontal",
  horizontalPosition,
  verticalPosition,
  showButton,
  setShowButton,
  activeStep,
  setActiveStep,
}: StepperProgressBarProps) {
  const isHorizontal = orientation === "horizontal";
  return (
    <div>
      <div
        className={`flex w-full flex-row items-center mb-6 rounded-s-lg rounded-e-lg overflow-hidden`}
      >
        {steps?.map((step, index) => (
          <div
            key={index}
            className="flex items-center w-1/3 relative z-0 bg-[#FBF0D0] "
          >
            {/* Adjusting progress bar */}
            <div
              className={`-z-[1] border-t-[6px]   ${
                activeStep === index
                  ? "w-[50%] border-site-yellow" // 50% width for the current step
                  : activeStep > index
                  ? "w-full border-site-yellow" // Full width for completed steps
                  : "w-0 border-light-grey" // No width for future steps
              }`}
            ></div>
          </div>
        ))}
      </div>

      {steps[activeStep].content}
    </div>
  );
}

export default StepperWithProgressbar;
