import React from "react";
import AuthenticationMockup from "../../assets/images/authentication-mockup.png";
import { Outlet } from "react-router-dom";

function AuthenticationWrapper() {
  return (
    <main className="">
      <div className="flex items-stretch h-screen overflow-hidden">
        <div className={`sticky img-block xl:w-[55%] lg:w-[50%] top-0`}>
          <div className="bg-authentication-mockup bg-no-repeat bg-cover h-screen"></div>
        </div>
        <div className="xl:w-[45%] lg:w-[50%] md:w-3/5 md:mx-auto w-full h-auto overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default AuthenticationWrapper;
