import React from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import Art1 from "../../../assets/images/popular-week-art1.png";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Profile from "../../../assets/images/dummy_profile.png";

function ArtistOfWeek() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4.5,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <div className="xl:py-6 py-4 md:pb-4 pb-0 xl:ps-30 lg:px-5 md:px-4 px-3 xl:pe-10">
      <Paragraph text32 className="lg:mb-6 md:mb-5 mb-3">
        Artist of the week
      </Paragraph>
      <ul className="hidden md:block">
        <li className="flex items-center gap-2.5 pb-3 border-b border-medium-grey mb-3">
          <Link to={""} className="relative">
            <img
              src={Art1}
              alt="user-profile"
              title="user-profile"
              width={60}
              height={60}
              className="rounded-md w-full h-full object-cover max-w-[60px] flex-shrink-0"
            />
          </Link>
          <span className="block">
            <Link to={""} className="text-base leading-5">
              Lucie Moreau-Duchamp
            </Link>
            <SwitchToggle withInnerText />
          </span>
        </li>
        <li className="flex items-center gap-2.5 pb-3 border-b border-medium-grey mb-3">
          <Link to={""} className="relative">
            <img
              src={Art1}
              alt="user-profile"
              title="user-profile"
              width={60}
              height={60}
              className="rounded-md w-full h-full object-cover max-w-[60px] flex-shrink-0"
            />
          </Link>
          <span className="block">
            <Link to={""} className="text-base leading-5">
              Lucie Moreau-Duchamp
            </Link>
            <SwitchToggle withInnerText />
          </span>
        </li>
        <li className="flex items-center gap-2.5 pb-3 border-b border-medium-grey mb-3">
          <Link to={""} className="relative">
            <img
              src={Art1}
              alt="user-profile"
              title="user-profile"
              width={60}
              height={60}
              className="rounded-md w-full h-full object-cover max-w-[60px] flex-shrink-0"
            />
          </Link>
          <span className="block">
            <Link to={""} className="text-base leading-5">
              Lucie Moreau-Duchamp
            </Link>
            <SwitchToggle withInnerText />
          </span>
        </li>
        <li className="flex items-center gap-2.5 pb-3 border-b border-medium-grey mb-3">
          <Link to={""} className="relative">
            <img
              src={Art1}
              alt="user-profile"
              title="user-profile"
              width={60}
              height={60}
              className="rounded-md w-full h-full object-cover max-w-[60px] flex-shrink-0"
            />
          </Link>
          <span className="block">
            <Link to={""} className="text-base leading-5">
              Lucie Moreau-Duchamp
            </Link>
            <SwitchToggle withInnerText />
          </span>
        </li>
        <li className="flex items-center gap-2.5 pb-3 border-b border-medium-grey mb-3">
          <Link to={""} className="relative">
            <img
              src={Art1}
              alt="user-profile"
              title="user-profile"
              width={60}
              height={60}
              className="rounded-md w-full h-full object-cover max-w-[60px] flex-shrink-0"
            />
          </Link>
          <span className="block">
            <Link to={""} className="text-base leading-5">
              Lucie Moreau-Duchamp
            </Link>
            <SwitchToggle withInnerText />
          </span>
        </li>
      </ul>
      <Slider {...settings} className="md:hidden block">
        <div className="pe-2">
          <div className="col-span-1 rounded-xl overflow-hidden">
            <Link to={""} className="relative">
              <img
                src={Art1}
                alt="popular-art"
                width={152}
                height={144}
                className="w-full h-full object-cover"
              />
              <div className="absolute w-full left-0 bottom-0 flex flex-col gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                <Paragraph text14 className="!text-white !font-normal">
                  Lucie Moreau-Duchamp
                </Paragraph>
              </div>
            </Link>
          </div>
        </div>
        <div className="pe-2">
          <div className="col-span-1 rounded-xl overflow-hidden">
            <Link to={""} className="relative">
              <img
                src={Art1}
                alt="popular-art"
                width={152}
                height={144}
                className="w-full h-full object-cover"
              />
              <div className="absolute w-full left-0 bottom-0 flex items-center gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                <Paragraph text14 className="!text-white !font-normal">
                  Lucie Moreau-Duchamp
                </Paragraph>
              </div>
            </Link>
          </div>
        </div>
        <div className="pe-2">
          <div className="col-span-1 rounded-xl overflow-hidden">
            <Link to={""} className="relative">
              <img
                src={Art1}
                alt="popular-art"
                width={152}
                height={144}
                className="w-full h-full object-cover"
              />
              <div className="absolute w-full left-0 bottom-0 flex items-center gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                <Paragraph text14 className="!text-white !font-normal">
                  Lucie Moreau-Duchamp
                </Paragraph>
              </div>
            </Link>
          </div>
        </div>
        <div className="pe-2">
          <div className="col-span-1 rounded-xl overflow-hidden">
            <Link to={""} className="relative">
              <img
                src={Art1}
                alt="popular-art"
                width={152}
                height={144}
                className="w-full h-full object-cover"
              />
              <div className="absolute w-full left-0 bottom-0 flex items-center gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                <Paragraph text14 className="!text-white !font-normal">
                  Lucie Moreau-Duchamp
                </Paragraph>
              </div>
            </Link>
          </div>
        </div>
        <div className="">
          <div className="col-span-1 rounded-xl overflow-hidden">
            <Link to={""} className="relative">
              <img
                src={Art1}
                alt="popular-art"
                width={152}
                height={144}
                className="w-full h-full object-cover"
              />
              <div className="absolute w-full left-0 bottom-0 flex items-center gap-[6px] px-[10px] py-[6px] bg-gradient-to-r from-red -from-[4.18%] backdrop-blur-[19px] to-transparent to-[104.23%]">
                <Paragraph text14 className="!text-white !font-normal">
                  Lucie Moreau-Duchamp
                </Paragraph>
              </div>
            </Link>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default ArtistOfWeek;
