import React, { useState } from "react";
import { Form, Formik } from "formik";
import FormLabel from "../../../components/core/typography/FormLabel";
import TextArea from "../../../components/core/form-components/TextArea";
import RadioButton from "../../../components/core/form-components/RadioButton";
import UploadFile from "../../../assets/images/icon_image_upload.svg";
import Button from "../../../components/core/form-components/Button";
import Paragraph from "../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import InputType from "../../../components/core/form-components/InputType";
import SelectType from "../../../components/core/form-components/SelectType";
import Modal from "../../../components/core/Modal";
import ColorWheel from "../../../assets/images/icon_color_wheel.svg";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import IconLock from "../../../assets/images/icon_lock.svg";
import IconUnLock from "../../../assets/images/icon_unlock.svg";

interface FormValues {
  otp: string;
}

function CreateEvent() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const [modalOpen, setModalOpen] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const [colorPickerModal, setColorPickerModal] = useState(false);
  const options = [
    {
      label: "Option 1",
      value: "option-1",
    },
    {
      label: "Option 2",
      value: "option-2",
    },
    {
      label: "Option 3",
      value: "option-3",
    },
  ];
  const onSubmit = (values: FormValues) => {
    console.log("Form Data: ", values);
  };
  return (
    <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3 ">
      <Paragraph text24 className="md:mb-6 sm:mb-5 mb-4">
        {LanguageTranslation({
          labelName: "create_event",
          languageCode: langId || "fr",
        }) || "Create Event"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl overflow-hidden">
        <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <div className="grid grid-cols-12">
                <div className="xl:col-span-3 sm:col-span-4 col-span-12 bg-light-grey xl:p-30 md:p-5 p-3">
                  <FormLabel>
                    {LanguageTranslation({
                      labelName: "event_image",
                      languageCode: langId || "fr",
                    }) || "Event Image"}
                  </FormLabel>
                  <label
                    htmlFor="upload-file"
                    className="px-4 py-3.5 md:h-[180px] h-[120px] bg-white rounded-lg border border-dashed border-medium-grey flex flex-col justify-center items-center gap-3 cursor-pointer"
                  >
                    <input
                      type="file"
                      name="upload-file"
                      id="upload-file"
                      className="sr-only"
                    />
                    <img
                      src={UploadFile}
                      alt="upload-file"
                      width={30}
                      height={30}
                    />
                    <span className="text-sm leading-3 text-dark-grey">
                      Upload file
                    </span>
                  </label>
                </div>
                <div className="xl:col-span-9 sm:col-span-8 col-span-12 xl:p-30 md:p-5 p-3">
                  <div className="grid grid-cols-12 lg:gap-6 md:gap-5 gap-3">
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "event_name",
                          languageCode: langId || "fr",
                        }) || "Event Name"}
                      </FormLabel>
                      <InputType
                        name="event-name"
                        placeholder="Enter event name"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "location",
                          languageCode: langId || "fr",
                        }) || "Location"}
                      </FormLabel>
                      <SelectType
                        options={options}
                        fullWidth
                        onChange={onSubmit}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "start_date_time",
                          languageCode: langId || "fr",
                        }) || "Start Date & time"}
                      </FormLabel>
                      <InputType
                        name="start-date-time"
                        placeholder="Enter"
                        type="date"
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "end_date_time",
                          languageCode: langId || "fr",
                        }) || "End Date & time"}
                      </FormLabel>
                      <InputType
                        name="end-date-time"
                        placeholder="Enter"
                        type="date"
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center gap-4 mb-4">
                        <label
                          htmlFor="private"
                          className="border border-medium-grey bg-light-grey rounded-lg px-3 py-1.5 flex items-center gap-2 has-[:checked]:bg-site-yellow cursor-pointer"
                        >
                          <input
                            type="radio"
                            name="event-privacy"
                            id="private"
                            className="sr-only"
                          />
                          <img
                            src={IconLock}
                            alt="lock"
                            width={16}
                            height={16}
                          />
                          <Paragraph
                            text18
                            className="!font-semibold !leading-[12px]"
                          >
                            Private
                          </Paragraph>
                        </label>
                        <label
                          htmlFor="public"
                          className="border border-medium-grey bg-light-grey rounded-lg px-3 py-1.5 flex items-center gap-2 has-[:checked]:bg-site-yellow cursor-pointer"
                        >
                          <input
                            type="radio"
                            name="event-privacy"
                            id="public"
                            className="sr-only"
                          />
                          <img
                            src={IconUnLock}
                            alt="lock"
                            width={16}
                            height={16}
                          />
                          <Paragraph
                            text18
                            className="!font-semibold !leading-[12px]"
                          >
                            Public
                          </Paragraph>
                        </label>
                      </div>
                      <Paragraph text16 className="!text-dark-grey">
                        {LanguageTranslation({
                          labelName: "subscriber_will_see_event",
                          languageCode: langId || "fr",
                        }) || "Only your subscribers will see your event"}
                      </Paragraph>
                    </div>
                    <div className="col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "description",
                          languageCode: langId || "fr",
                        }) || "Description"}
                      </FormLabel>
                      <TextArea
                        name="description"
                        rows={3}
                        placeholder="What do you want to share?"
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>Google url</FormLabel>
                      <InputType
                        required
                        name="google_url"
                        type="url"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>iCal url</FormLabel>
                      <InputType
                        required
                        name="iCal_url"
                        type="url"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>Yahoo url</FormLabel>
                      <InputType
                        required
                        name="yahoo_url"
                        type="url"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>Outlook url</FormLabel>
                      <InputType
                        required
                        name="outlook_url"
                        type="url"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center gap-[30px]">
                        <Paragraph text16>Entrance fee</Paragraph>
                        <SwitchToggle />
                      </div>
                    </div>
                    <div className="lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>Tracking url</FormLabel>
                      <InputType
                        required
                        name="tracking-url"
                        type="url"
                        placeholder="Enter"
                      />
                    </div>
                    <div className="lg:col-span-6  sm:col-span-6 col-span-12">
                      <FormLabel>Price</FormLabel>
                      <InputType
                        required
                        name="price"
                        type="number"
                        placeholder="EUROS"
                      />
                    </div>

                    <div className="col-span-12 mt-1.5">
                      <div className="flex items-center xl:justify-end justify-start gap-4">
                        <Button borderButton className="lg:w-auto w-full">
                          Cancel
                        </Button>
                        <Button disabled className="lg:w-auto w-full">
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreateEvent;
