import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserInitialRedux {
  language: LanguageInterface | null;
}

interface LanguageInterface {
  languageId: string;
}

const initialState = {
  language: { languageId: "fr" },
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguageId: (
      state: IUserInitialRedux,
      action: PayloadAction<LanguageInterface | null>
    ) => {
      state.language = action.payload;
    },
  },
});

export const languageIdSelector = (state: { language: IUserInitialRedux }) =>
  state.language.language;

const { actions, reducer } = languageSlice;

export const { setLanguageId } = actions;

export const setlanguageid = (data: LanguageInterface | null) => {
  setLanguageId(data);
};

export default reducer;
