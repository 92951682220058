import React, { useState } from "react";
import SlidingTabBar from "../../../components/core/SlidingTabBar";
import { Tab } from "../../../interface/Tabs";
import Feed from "../../portfolio/feed/Feed";
import ArtRoom from "../../portfolio/art-room/ArtRoom";
import ProfileCover from "../../../assets/images/profile_cover.png";
import MyProfileImg from "../../../assets/images/dummy_profile_big.png";
import IconCamera from "../../../assets/images/icon_camera.svg";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { IoEllipsisVertical, IoQrCode } from "react-icons/io5";
import Paragraph from "../../../components/core/typography/Paragraph";
import { ImLocation } from "react-icons/im";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import { FaEnvelope } from "react-icons/fa";
import FollowProfile from "../../../assets/images/dummy_profile_small.svg";
import FollowProfile1 from "../../../assets/images/dummy_profile.png";
import Event from "../event/Event";
import Repost from "../repost/Repost";

function MyProfile() {
  const [isOpen, setIsOpen] = useState(false);
  const profileDropDownItems = [
    { Link: "", LinkName: "Report" },
    {
      Link: "",
      LinkName: "Block",
    },
  ];
  const tabs: Tab[] = [
    { id: "art_room", name: "Art Room", content: <ArtRoom isWebsite={true} /> },
    { id: "feed", name: "Feed", content: <Feed isWebsite={true} /> },
    { id: "event", name: "Event", content: <Event /> },
    { id: "repost", name: "Repost", content: <Repost /> },
  ];
  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };
  return (
    <div className="py-6 sm:pl-4 pl-3 xl:pr-10 lg:pr-4 pr-3">
      <div className="grid grid-cols-12 xl:gap-[48px] gap-6">
        <div className="xl:col-span-5 lg:col-span-10 col-span-12">
          <div className="border border-medium-grey rounded-2xl overflow-hidden">
            <div className="relative">
              <div className="relative -z-[1]">
                <img
                  src={ProfileCover}
                  alt="profile-cover"
                  width={438}
                  height={115}
                  className="w-full h-[115px] object-cover"
                />
                <label
                  htmlFor="cover"
                  className="w-6 h-6 rounded-full absolute bottom-3 right-3 inline-flex items-center justify-center bg-gradient-to-r from-[#8C8C8C] -from-[6.79%] to-[#222433]/0 to-[155.78%] backdrop-blur-[15px] cursor-pointer"
                >
                  <input type="file" name="" id="cover" className="sr-only" />
                  <img
                    src={IconCamera}
                    alt="icon-camera"
                    width={15}
                    height={15}
                  />
                </label>
              </div>
              <div className="flex justify-between items-center sm:px-4 px-3 sm:-mt-[48px] -mt-[36px] mb-3">
                <div className="relative">
                  <img
                    src={MyProfileImg}
                    alt="profile-picture"
                    width={100}
                    height={100}
                    className="rounded-full object-cover sm:w-[100px] sm:h-[100px] w-[80px] h-[80px]"
                  />
                  <label
                    htmlFor="cover"
                    className="w-6 h-6 rounded-full absolute bottom-3 -right-1.5 inline-flex items-center justify-center bg-gradient-to-r from-[#8C8C8C] -from-[6.79%] to-[#222433]/0 to-[155.78%] backdrop-blur-[15px] cursor-pointer"
                  >
                    <input type="file" name="" id="cover" className="sr-only" />
                    <img
                      src={IconCamera}
                      alt="icon-camera"
                      width={15}
                      height={15}
                    />
                  </label>
                </div>
                <IoQrCode size={20} color="#4D4B4B" className="mt-10" />
              </div>
              <div className="flex items-center sm:flex-nowrap flex-wrap gap-2 justify-between sm:px-4 px-3 pb-3">
                <div className="">
                  <Paragraph text18 className="font-medium mb-1 !leading-4">
                    @Elena Gilbert
                  </Paragraph>
                  <Paragraph
                    text16
                    className="!text-[#4D4B4B] !leading-[14px] !font-normal flex items-center gap-1"
                  >
                    <ImLocation size={14} />
                    France
                  </Paragraph>
                </div>
                <a
                  href="#"
                  className="bg-site-yellow px-3 py-2 pb-1.5 rounded-[50px] inline-flex items-center gap-1 text-sm leading-4 font-medium"
                >
                  Edit Profile
                </a>
              </div>
              <div className="border-b border-medium-grey sm:px-4 px-3 pb-4">
                <div className="flex items-center gap-2">
                  <Paragraph text16 className="!leading-[15px] !font-normal">
                    200 Followers
                  </Paragraph>
                  <span className="w-[3px] h-[3px] rounded-full bg-site-grey"></span>
                  <Paragraph text16 className="!leading-[15px] !font-normal">
                    13 Post
                  </Paragraph>
                </div>
              </div>
              <div className="py-4 sm:px-4 px-3">
                <Paragraph text16 className="!leading-4 mb-3 !font-medium">
                  Interest
                </Paragraph>
                <div className="flex items-center gap-2 flex-wrap mb-4">
                  <span className="border border-medium-grey rounded-2xl text-sm leading-4 font-medium px-3 py-1">
                    Drawing
                  </span>
                  <span className="border border-medium-grey rounded-2xl text-sm leading-4 font-medium px-3 py-1">
                    Sculpture
                  </span>
                  <span className="border border-medium-grey rounded-2xl text-sm leading-4 font-medium px-3 py-1">
                    Painting
                  </span>
                  <span className="border border-medium-grey rounded-2xl text-sm leading-4 font-medium px-3 py-1">
                    Media
                  </span>
                </div>
                <Paragraph text16 className="!leading-4 mb-2.5 !font-medium">
                  About
                </Paragraph>
                <Paragraph
                  text14
                  className="leading-[18px] !text-[#4D4B4B] !font-normal"
                >
                  Hey, when life derails your plans, why not switch tracks?
                  That's exactly what I did. I swapped engine blueprints for
                  digital and blueprints, turning challenges into victories and
                  pixels into profits.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-7 lg:col-span-10 col-span-12">
          <div className="border border-medium-grey rounded-2xl overflow-hidden pb-30">
            <SlidingTabBar tabs={tabs} borderedTab />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
