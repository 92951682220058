import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  emailNumber: Yup.string()
    .email("Email is invalid")
    .trim()
    .max(255, "Maximum 255 Characters allowed")
    .required("Email or phone number is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Email is invalid"),
  password: Yup.string()
    .trim()
    .max(255, "Maximum 255 Characters allowed")
    .min(6, "Minimum 6 Characters required")
    .required("Password is required"),
});

export const phoneNumberValidationSchema = Yup.object({
  emailNumber: Yup.string()
    .matches(/^\d+$/, "Phone number must be numeric")
    .max(15, "Maximum 15 digits allowed")
    .min(10, "Minimum 10 digits required")
    .required("Email or phone number is required"),
});
