import React, { useEffect, useState } from "react";
import DummyProfile from "../../../assets/images/dummy_profile.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaPlayCircle } from "react-icons/fa";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/core/Breadcrumb";
import { tokenSelector } from "../../../redux/slices/userSlice";
import { ArtDetails } from "../../../interface/ArtDetails";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { getArtRoomDetailsById } from "../../../services/profileService";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import { pathRoute } from "../../../routes/pathRoute";
import DummyThumb from "../../../assets/images/art1.png";

function ArtRoomDetail() {
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileUrlToken = `${pathRoute.web.profile}?token=${token}`;
  const artUrlToken = `${pathRoute.web.artDetail}/${id}?token=${token}`;

  const [artDetails, setArtDetails] = useState<ArtDetails | undefined>(
    undefined
  );
  const isPortfolio = false;
  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const fetchArtRoomDetails = async (id: string) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getArtRoomDetailsById(parseInt(id));
        if (response?.data?.code === 200) {
          setArtDetails(response?.data?.art);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchArtRoomDetails(id);
    }
  }, [id]);

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const handleBuyNowClick = () => {
    const urlWithToken = `${pathRoute.web.checkout}/${id}?token=${token}`;
    navigate(urlWithToken);
  };

  const breadcrumbPages = [
    { name: "Profile", path: profileUrlToken },
    { name: artDetails?.title || "", path: artUrlToken },
  ];

  return (
    <>
      <section>
        <div
          className={`${
            isPortfolio ? "container" : "py-4 xl:px-10 lg:px-6 md:px-4 px-3"
          } `}
        >
          <div className={`${isPortfolio ? "pt-4" : "pt-0"}`}>
            <Breadcrumb pages={breadcrumbPages} />
          </div>
          <div
            className={`${isPortfolio ? "md:py-[50px] py-[30px]" : "pt-6"} `}
          >
            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
              <div className="col-span-1">
                <div className="sticky top-4">
                  <div className="bg-light-grey w-full rounded-[20px] flex items-center justify-center  mb-3">
                    {artDetails?.video ? (
                      <div className="relative">
                        <img
                          src={artDetails.video?.imageUrl}
                          className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
                          alt="video-thumbnail"
                        />
                        <span className="absolute inset-0 flex items-center justify-center ">
                          <FaPlayCircle
                            size={40}
                            className="text-base cursor-pointer"
                            onClick={() =>
                              openLightbox(artDetails?.video?.videoUrl)
                            }
                          />
                        </span>
                      </div>
                    ) : (
                      <img
                        src={
                          artDetails?.images && artDetails?.images[0]?.imageUrl
                        }
                        alt="artt-imgg"
                        className="inline-block max-h-[360px]"
                      />
                    )}
                  </div>
                  <div className="grid grid-cols-5 gap-2">
                    <div className="col-span-1">
                      <div className="bg-light-grey w-full rounded-[20px]">
                        <img
                          src={DummyThumb}
                          alt="artt-imgg"
                          className="inline-block max-h-[360px]"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="bg-light-grey w-full rounded-[20px]">
                        <img
                          src={DummyThumb}
                          alt="artt-imgg"
                          className="inline-block max-h-[360px]"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="bg-light-grey w-full rounded-[20px]">
                        <img
                          src={DummyThumb}
                          alt="artt-imgg"
                          className="inline-block max-h-[360px]"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="bg-light-grey w-full rounded-[20px]">
                        <img
                          src={DummyThumb}
                          alt="artt-imgg"
                          className="inline-block max-h-[360px]"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="bg-light-grey w-full rounded-[20px]">
                        <img
                          src={DummyThumb}
                          alt="artt-imgg"
                          className="inline-block max-h-[360px]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <Paragraph
                  text24
                  className="!font-semibold text-site-black mb-3"
                >
                  {artDetails?.title}
                </Paragraph>
                <div className="flex items-center gap-2 mb-3">
                  <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                    <img
                      src={getProfileImage(artDetails?.owner?.avatar ?? "")}
                      alt="profile-photo"
                    />
                  </div>
                  <Paragraph text16 className="font-medium">
                    <span className="text-site-dark-black/40 ">by</span>{" "}
                    {artDetails?.owner?.nickname}
                  </Paragraph>
                </div>
                {artDetails?.owner?.description && (
                  <Paragraph text18 className="mb-4 font-normal">
                    {artDetails?.owner?.description}
                  </Paragraph>
                )}
                <Paragraph text18 className="mb-2 font-normal">
                  <span className="font-medium">{t("profile.art")} | </span>
                  {artDetails?.width}*{artDetails?.height}cm
                </Paragraph>
                <Paragraph text18 className="mb-4 font-normal">
                  <span className="font-medium">
                    {t("profile.available_piece")} |{" "}
                  </span>
                  {artDetails?.quantity} {t("profile.copies_left")}.
                </Paragraph>
                <Paragraph text24 className="!font-semibold md:mb-7 mb-5">
                  €{artDetails?.price}
                </Paragraph>
                <Button
                  primary
                  className="w-full md:mb-[30px] mb-5"
                  onClick={handleBuyNowClick}
                  disabled={artDetails?.quantity === 0}
                >
                  {artDetails?.quantity === 0
                    ? t("profile.out_of_Stock")
                    : t("profile.buy_now")}
                </Button>

                {artDetails?.description && isPortfolio && (
                  <>
                    <Paragraph text20 className="font-medium mb-3">
                      {t("profile.description")}
                    </Paragraph>
                    <Paragraph
                      text18
                      className="font-normal !text-dark-grey mb-4"
                    >
                      {artDetails?.description}
                    </Paragraph>
                  </>
                )}
                <ul className="flex items-center lg:gap-[65px] md:gap-7 sm:gap-5 gap-3">
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.weight")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.weight} kg
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.width")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.width} cm
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.height")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.height} cm
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.depth")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.depth} cm
                    </Paragraph>
                  </li>
                </ul>
                {!isPortfolio && (
                  <>
                    <div className="border-t border-medium-grey mt-4 pt-4">
                      <ul>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Category
                          </Paragraph>
                          <Paragraph
                            text18
                            className="!leading-4 !font-normal !text-dark-grey"
                          >
                            Dessin
                          </Paragraph>
                        </li>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Tags
                          </Paragraph>
                          <Paragraph
                            text18
                            className="!leading-4 !font-normal !text-dark-grey"
                          >
                            danseur
                          </Paragraph>
                        </li>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Type of post
                          </Paragraph>
                          <Paragraph
                            text18
                            className="!leading-4 !font-normal !text-dark-grey"
                          >
                            All
                          </Paragraph>
                        </li>
                        <li className="flex items-center justify-between gap-3 pb-4">
                          <Paragraph text18 className="!leading-4 !font-normal">
                            Colourimetry
                          </Paragraph>
                          <div className="flex items-center gap-2">
                            <span className="w-6 h-6 rounded-full inline-block bg-[#D0BD86]"></span>
                            <span className="w-6 h-6 rounded-full inline-block bg-[#E5805B]"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="border-t border-medium-grey pt-4">
                      <Paragraph text20 className="font-medium mb-3">
                        {t("profile.description")}
                      </Paragraph>
                      <Paragraph text18 className="font-normal !text-dark-grey">
                        {artDetails?.description}
                      </Paragraph>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {lightboxOpen && (
        <div>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
}

export default ArtRoomDetail;
