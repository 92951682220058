import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface TextLinkProps {
  children: ReactNode;
  to: string;
  target?: string;
  className?: string;
}

function TextLink({ children, className, to, target }: TextLinkProps) {
  return (
    <NavLink
      to={to}
      target={target}
      className={`${className} inline-flex items-center font-semibold text-sm leading-[13px] text-site-black hover:text-site-yellow transition-all duration-700 ease-in-out`}
    >
      {children}
    </NavLink>
  );
}

export default TextLink;
