import React, { ReactNode, useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

interface DropdownItems {
  Link: string;
  LinkName: string;
}
interface DropdownProps {
  children: ReactNode;
  className?: string;
  dropdownItems: DropdownItems[];
  withChevron?: boolean;
  chevronColor?: string;
  innerClasses?: string;
  dropdownWidth?: string;
  onClick: (value: any) => void;
}

function Dropdown({
  children,
  className,
  dropdownItems,
  withChevron,
  chevronColor,
  innerClasses,
  dropdownWidth,
  onClick,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as HTMLDivElement)
    ) {
      closeDropdown();
    }
  };
  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
    closeDropdown();
  };

  const handleLogoutClose = () => {
    setIsLogoutModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <button
          type="button"
          className={`${className ? className : ""} block`}
          onClick={toggleDropdown}
        >
          <div className={`flex items-center lg:gap-3 gap-2 ${innerClasses}`}>
            {children}{" "}
            {withChevron ? (
              <IoIosArrowDown
                size={20}
                color={chevronColor}
                className={`transition-all ${isOpen ? "rotate-180" : ""}`}
              />
            ) : (
              ""
            )}
          </div>
        </button>

        {isOpen && (
          <div
            className={`origin-top-right pt-2 pb-0 absolute right-0 mt-2 rounded-[6px] shadow-dropdownShadow bg-white z-10 ${
              dropdownWidth ? dropdownWidth : "w-full"
            }`}
          >
            <ul
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {dropdownItems?.map((item, index) => {
                return item?.LinkName === "Logout" ? (
                  <li className="mb-1" key={index}>
                    <a
                      href={item?.Link}
                      className="block px-3 py-2 text-base leading-[16px] text-site-black font-normal hover:bg-light-grey"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLogoutClick();
                      }}
                    >
                      {item?.LinkName}
                    </a>
                  </li>
                ) : (
                  <li className="mb-1" key={index}>
                    <a
                      href={item?.Link}
                      className="block px-3 py-2 text-base leading-[16px] text-site-black font-normal hover:bg-light-grey"
                      onClick={closeDropdown}
                    >
                      {item?.LinkName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {/* {isLogoutModalOpen && (
        <Logout
          handleLogoutClose={handleLogoutClose}
          isLogoutModalOpen={isLogoutModalOpen}
        />
      )} */}
    </>
  );
}

export default Dropdown;
