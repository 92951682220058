import React from "react";
import Checkbox from "../../../components/core/form-components/Checkbox";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";

interface ColorPickerModalProps {
  onClose?: () => void;
  open: boolean;
}

function ColorPickerModal({ open, onClose }: ColorPickerModalProps) {
  return (
    <div>
      <Paragraph text20 className="font-medium mb-3">
        Colourimetry
      </Paragraph>
      <Paragraph text16 className="!text-dark-grey mb-3">
        Note: You can select a maximum of two colors.
      </Paragraph>
      <ul className="flex flex-col gap-3 mb-4">
        <li className="flex">
          <Checkbox name="colourimetry" id="black" w18>
            <div className="flex items-center gap-2">
              <span className="w-5 h-5 rounded-full block bg-black"></span>{" "}
              Black
            </div>
          </Checkbox>
        </li>
        <li className="flex">
          <Checkbox name="colourimetry" id="black" w18>
            <div className="flex items-center gap-2">
              <span className="w-5 h-5 rounded-full block bg-[#4F83E8]"></span>{" "}
              Blue
            </div>
          </Checkbox>
        </li>
      </ul>
      <div className="flex items-center justify-between gap-3 mt-5">
        <Button onClick={onClose} borderButton>
          Cancel
        </Button>
        <Button primary>Select Color</Button>
      </div>
    </div>
  );
}

export default ColorPickerModal;
