import React from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import MasterClassDummy1 from "../../../../assets/images/master_class_dummy_1.png";
import { IoMdPlay } from "react-icons/io";
import { GoClockFill } from "react-icons/go";

function MyMasterclass() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "my_masterclass",
          languageCode: langId || "fr",
        }) || "My Masterclass"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        <div className="flex items-start justify-between gap-3 mb-6">
          <div className="">
            <Paragraph text22 className="!font-medium mb-1">
              Achat s'une video unique
            </Paragraph>
            <Paragraph text16 className="!font-normal">
              Ceci concerne I'achat d'une masterclass de 10 min...
            </Paragraph>
          </div>
          <Paragraph text24 className="!font-semibold">
            €199
          </Paragraph>
        </div>
        <ul className="max-h-[calc(100vh-296px)] overflow-y-auto">
          <li className="border border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3">
            <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={60}
                height={90}
                className="aspect-[2/3]"
              />
              <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={8} />
              </span>
            </div>
            <div>
              <Paragraph text20 className="!font-medium mb-2">
                Comprendre ses besoins d’artiste
              </Paragraph>
              <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                <GoClockFill color="#4D4B4B" size={18} />
                Class: 35 min 59 sec
              </span>
            </div>
          </li>
          <li className="border border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3">
            <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={60}
                height={90}
                className="aspect-[2/3]"
              />
              <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={8} />
              </span>
            </div>
            <div>
              <Paragraph text20 className="!font-medium mb-2">
                Comprendre ses besoins d’artiste
              </Paragraph>
              <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                <GoClockFill color="#4D4B4B" size={18} />
                Class: 35 min 59 sec
              </span>
            </div>
          </li>
          <li className="border border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3">
            <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={60}
                height={90}
                className="aspect-[2/3]"
              />
              <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={8} />
              </span>
            </div>
            <div>
              <Paragraph text20 className="!font-medium mb-2">
                Comprendre ses besoins d’artiste
              </Paragraph>
              <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                <GoClockFill color="#4D4B4B" size={18} />
                Class: 35 min 59 sec
              </span>
            </div>
          </li>
          <li className="border border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3">
            <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={60}
                height={90}
                className="aspect-[2/3]"
              />
              <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={8} />
              </span>
            </div>
            <div>
              <Paragraph text20 className="!font-medium mb-2">
                Comprendre ses besoins d’artiste
              </Paragraph>
              <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                <GoClockFill color="#4D4B4B" size={18} />
                Class: 35 min 59 sec
              </span>
            </div>
          </li>
          <li className="border border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3">
            <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={60}
                height={90}
                className="aspect-[2/3]"
              />
              <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={8} />
              </span>
            </div>
            <div>
              <Paragraph text20 className="!font-medium mb-2">
                Comprendre ses besoins d’artiste
              </Paragraph>
              <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                <GoClockFill color="#4D4B4B" size={18} />
                Class: 35 min 59 sec
              </span>
            </div>
          </li>
          <li className="border border-medium-grey rounded-xl p-3 flex items-center gap-6 mb-3">
            <div className="overflow-hidden rounded-[4px] relative before:absolute before:w-full before:h-full before:bg-site-black/30">
              <img
                src={MasterClassDummy1}
                alt="video-thumb"
                width={60}
                height={90}
                className="aspect-[2/3]"
              />
              <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                <IoMdPlay color="#fff" size={8} />
              </span>
            </div>
            <div>
              <Paragraph text20 className="!font-medium mb-2">
                Comprendre ses besoins d’artiste
              </Paragraph>
              <span className="flex items-center gap-1 text-base leading-[16px] text-dark-grey">
                <GoClockFill color="#4D4B4B" size={18} />
                Class: 35 min 59 sec
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MyMasterclass;
