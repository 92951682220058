import React from "react";
import IconCheck from "../../../assets/images/icon_check_mark.svg";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";

interface AppliedSuccessfullProps {
  onClose?: () => void;
}
function AppliedSuccessfull({ onClose }: AppliedSuccessfullProps) {
  return (
    <div className="text-center">
      <img
        src={IconCheck}
        alt="icon-check"
        width={126}
        height={126}
        className="mb-30 inline-block"
      />
      <Paragraph className="mb-4" text24>
        You have successfully applied
      </Paragraph>
      <Paragraph className="mb-30 !leading-[30px]" text20>
        Your application has been sent , we will contact you by email or phone
        as soon as possible.
      </Paragraph>
      <Button primary onClick={onClose}>
        Thank you
      </Button>
    </div>
  );
}

export default AppliedSuccessfull;
