import React from "react";
import Logo from "../../../assets/images/black_logo.svg";
import HeadingOne from "../../../components/core/typography/HeadingOne";
import Paragraph from "../../../components/core/typography/Paragraph";
import FormLabel from "../../../components/core/typography/FormLabel";
import InputType from "../../../components/core/form-components/InputType";
import TextLink from "../../../components/core/form-components/TextLink";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/core/form-components/Button";
import { resetPasswordValidationSchema } from "../../../validations/website/resetPasswordValidationSchema";

interface RegisterFormValues {
  new_password: string;
  confirm_password: string;
}
function ResetPassword() {
  const initialValues: RegisterFormValues = {
    new_password: "",
    confirm_password: "",
  };

  const onSubmit = (values: RegisterFormValues) => {
    console.log("Form Data: ", values);
  };
  return (
    <div className="xl:px-[105px] lg:px-[60px] md:px-10 px-3 xxl:pt-15 lg:py-10 py-[30px]">
      <div className="text-center xl:mb-[60px] lg:mb-[40px] mb-[30px]">
        <img
          src={Logo}
          alt="logo"
          width={180}
          height={74}
          className="text-center inline-block"
        />
      </div>
      <Paragraph text24 className="mb-[10px] !font-semibold">
        Reset Password
      </Paragraph>
      <Paragraph text18 className="font-normal mb-6 !text-dark-grey">
        Set the new password for your account so you can login and access all
        the features.
      </Paragraph>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form>
            <div className="form-group mb-6">
              <FormLabel>
                New Password<span className="text-site-red">*</span>
              </FormLabel>
              <InputType
                placeholder="Type here"
                type="password"
                name="new_password"
              />
            </div>
            <div className="form-group mb-6">
              <FormLabel>
                Confirm Password<span className="text-site-red">*</span>
              </FormLabel>
              <InputType
                placeholder="Type here"
                type="password"
                name="confirm_password"
              />
            </div>
            <Button
              primary
              type="submit"
              className="w-full"
              disabled={isSubmitting}
            >
              Reset
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
