import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { pathRoute } from "./pathRoute";
// import { useSelector } from "react-redux";

interface GuestRouteProps {
  children: ReactNode; // Children to render if the user is a guest
}

const GuestRoute = ({ children }: GuestRouteProps) => {
  const location = useLocation();
  const isToken = false; // Replace with actual token logic
  const renderDashboard = pathRoute.website.dashboard; // Update to your correct path

  if (isToken) {
    // Redirect them to the home page if they are logged
    return <Navigate to={renderDashboard} state={{ from: location }} />;
  }

  return <>{children}</>; // Wrap children with a fragment
};

export default GuestRoute;
