import French from "../assets/images/flag_french.svg";
import China from "../assets/images/flag_china.svg";

export const ProductStatus = {
  Exposed: "visible",
  OnSale: "sellable",
  Sold: "sold",
  OnStandBy: "standby",
  processing: "processing",
};
export const DEFAULT_DEVISE = "€";
export const CONSTANTS = {
  FRENCH_ABV: "fr",
  ENGLISH_ABV: "en",
};

export const countryOptions = [
  {
    label: "Chinese",
    value: "zh",
    image: China,
  },
  {
    label: "French",
    value: "fr",
    image: French,
    selected: true,
  },
  {
    label: "English",
    value: "en",
    image: China,
  },
  {
    label: "Japanese",
    value: "ja",
    image: China,
  },
  {
    label: "German",
    value: "de",
    image: China,
  },
  {
    label: "Italian",
    value: "it",
    image: China,
  },
  {
    label: "Spanish",
    value: "es",
    image: China,
  },
  {
    label: "Potuguese",
    value: "pt",
    image: China,
  },
  {
    label: "Dutch",
    value: "nl",
    image: China,
  },
  {
    label: "Hindi",
    value: "hi",
    image: China,
  },
];
