import React from "react";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import FormLabel from "../../../../../components/core/typography/FormLabel";
import InputType from "../../../../../components/core/form-components/InputType";
import Button from "../../../../../components/core/form-components/Button";

interface ConfirmPasswordValues {
  new_password: string;
  confirm_password: string;
}

function ChangePassword() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const initialValues: ConfirmPasswordValues = {
    new_password: "",
    confirm_password: "",
  };

  const onSubmit = (values: ConfirmPasswordValues) => {
    console.log("Form Data: ", values);
  };
  return (
    <div className="lg:p-30 md:p-4 p-3 !pt-0 grid grid-cols-12">
      <div className="xl:col-span-8 col-span-12">
        <Formik
          initialValues={initialValues}
          // validationSchema={resetPasswordValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "current_password",
                    languageCode: langId || "fr",
                  }) || "Current Password"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Type here"
                  type="password"
                  name="current_password"
                />
              </div>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "new_password",
                    languageCode: langId || "fr",
                  }) || "New Password"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Type here"
                  type="password"
                  name="new_password"
                />
              </div>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  Confirm Password<span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Type here"
                  type="password"
                  name="confirm_password"
                />
              </div>
              <div className="flex items-center gap-4">
                <Button disabled>Update</Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
