import React from "react";
import DummyLogo from "../../../assets/images/dummy_logo.png";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import Paragraph from "../../../components/core/typography/Paragraph";
import IconUsers from "../../../assets/images/icon_users.svg";
import OpportunityDummy from "../../../assets/images/opportunitiy_dummy.png";
import { FaCircleCheck } from "react-icons/fa6";
import { pathRoute } from "../../../routes/pathRoute";

function Opportunities() {
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 md:gap-[18px] gap-4 xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="col-span-1">
        <Link to={pathRoute.website.opportunityDetail}>
          <div className="sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3">
            <img src={DummyLogo} alt="dummy-logo" />
          </div>
          <span className="text-sm leading-4 font-medium text-site-grey inline-flex items-center gap-1 mb-1">
            Apply
          </span>
          <Paragraph text20 className="font-medium mb-2">
            Popstar Summer Party
          </Paragraph>
          <Paragraph
            text16
            className="!leading-4 !text-dark-grey flex items-center gap-1"
          >
            <img src={IconUsers} alt="icon-users" width={20} height={20} />
            Candidates: 4
          </Paragraph>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.opportunityDetail}>
          <div className="sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3">
            <img
              src={OpportunityDummy}
              alt="dummy-logo"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-sm leading-5 font-medium text-site-green inline-flex items-center gap-1 mb-1">
            <FaCircleCheck color="#1AB53C" size={16} />
            Applied
          </span>
          <Paragraph text20 className="font-medium mb-2">
            Popstar Summer Party
          </Paragraph>
          <Paragraph
            text16
            className="!leading-4 !text-dark-grey flex items-center gap-1"
          >
            <img src={IconUsers} alt="icon-users" width={20} height={20} />
            Candidates: 4
          </Paragraph>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.opportunityDetail}>
          <div className="sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3">
            <img
              src={OpportunityDummy}
              alt="dummy-logo"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-sm leading-5 font-medium text-site-green inline-flex items-center gap-1 mb-1">
            <FaCircleCheck color="#1AB53C" size={16} />
            Applied
          </span>
          <Paragraph text20 className="font-medium mb-2">
            Popstar Summer Party
          </Paragraph>
          <Paragraph
            text16
            className="!leading-4 !text-dark-grey flex items-center gap-1"
          >
            <img src={IconUsers} alt="icon-users" width={20} height={20} />
            Candidates: 4
          </Paragraph>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.opportunityDetail}>
          <div className="sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3">
            <img
              src={OpportunityDummy}
              alt="dummy-logo"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-sm leading-5 font-medium text-site-green inline-flex items-center gap-1 mb-1">
            <FaCircleCheck color="#1AB53C" size={16} />
            Applied
          </span>
          <Paragraph text20 className="font-medium mb-2">
            Popstar Summer Party
          </Paragraph>
          <Paragraph
            text16
            className="!leading-4 !text-dark-grey flex items-center gap-1"
          >
            <img src={IconUsers} alt="icon-users" width={20} height={20} />
            Candidates: 4
          </Paragraph>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.opportunityDetail}>
          <div className="sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3">
            <img
              src={OpportunityDummy}
              alt="dummy-logo"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-sm leading-5 font-medium text-site-green inline-flex items-center gap-1 mb-1">
            <FaCircleCheck color="#1AB53C" size={16} />
            Applied
          </span>
          <Paragraph text20 className="font-medium mb-2">
            Popstar Summer Party
          </Paragraph>
          <Paragraph
            text16
            className="!leading-4 !text-dark-grey flex items-center gap-1"
          >
            <img src={IconUsers} alt="icon-users" width={20} height={20} />
            Candidates: 4
          </Paragraph>
        </Link>
      </div>
      <div className="col-span-1">
        <Link to={pathRoute.website.opportunityDetail}>
          <div className="sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3">
            <img
              src={OpportunityDummy}
              alt="dummy-logo"
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-sm leading-5 font-medium text-site-green inline-flex items-center gap-1 mb-1">
            <FaCircleCheck color="#1AB53C" size={16} />
            Applied
          </span>
          <Paragraph text20 className="font-medium mb-2">
            Popstar Summer Party
          </Paragraph>
          <Paragraph
            text16
            className="!leading-4 !text-dark-grey flex items-center gap-1"
          >
            <img src={IconUsers} alt="icon-users" width={20} height={20} />
            Candidates: 4
          </Paragraph>
        </Link>
      </div>
    </div>
  );
}

export default Opportunities;
