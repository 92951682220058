import React from "react";
import { useNavigate } from "react-router-dom";

type BreadcrumbProps = {
  pages: { name: string; path?: string }[];
};

const Breadcrumb = ({ pages }: BreadcrumbProps) => {
  const navigate = useNavigate();

  const handleBreadcrumbClick = (path?: string, index?: number) => {
    if (path) {
      navigate(path);
    } else if (index !== undefined && index < 0) {
      // Go back in history by a certain number of steps
      navigate(index);
    }
  };

  return (
    <ul className="flex items-center gap">
      {pages?.map((page, index) => (
        <li
          className={`pr-3 mr-1 relative ${
            index !== pages.length - 1
              ? "before:absolute before:right-0 before:content-['/'] before:text-site-grey"
              : ""
          }`}
        >
          {index !== pages.length - 1 ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleBreadcrumbClick(page.path, index - pages.length + 1); // Go back by appropriate steps
              }}
              className="text-site-grey text-sm font-medium"
            >
              {page.name}
            </a>
          ) : (
            <span className="text-sm font-medium">{page.name}</span> // Current page as the last breadcrumb without a link
          )}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
